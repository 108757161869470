import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Form, Input, Modal } from 'antd'

import { authStore, stateStore } from '../../store/index.js'
import { PATHS } from '../../constants'

import '../../styles/custom/forms.css'



const TermsAndPrivacy = observer(() => {
  return (
    <div>
        <h1>PRIVACY POLICY</h1>
        <p>
        <strong>
            Effective Date: <time datetime="2020-04-09">April 9, 2020</time>.
        </strong>
        </p>
        <p>
        Thank you for visiting The COVID Network. The COVID Network helps
        facilitate the provision of medical supplies to those in need by
        connecting trusted suppliers of medical equipment (&ldquo;Aid
        Organizations&rdquo;) to hospitals, healthcare workers, emergency
        responders, and other eligible service personnel
        (&ldquo;Requestors&rdquo;). The COVID Network is sponsored and
        operated by the Helena Group Foundation (&ldquo;Helena,&rdquo;
        &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;), a
        non-profit organization. This Privacy Policy describes our data
        practices in relation to the information collected through the COVID
        Network, online and off. Your use of the COVID Network site and
        services constitutes acceptance of this Privacy Policy.
        </p>
        <p>
        If you require a copy of this Privacy Policy in an alternative
        accessible format, please contact us at covidnetwork@helena.org.
        </p>
        <h1>INFORMATION WE COLLECT</h1>
        <p>
        We collect different types of personal information, along with
        non-personal information about your supply needs or capabilities,
        during your interactions with the COVID Network on and offline. This
        information may include contact information such as your name,
        email, phone number technical information as well as professional
        information such as your workplace ID, title and logistics
        information related to your workplace, in additional to technical
        information about your use of our site, applications or other online
        services (e.g., device identifier, IP address, browser type, GPS
        coordinates, operating system, how you use and navigate to and from
        our Sites, and information about content you have been shown or have
        clicked on).
        </p>
        <p>
        Personal information may be collected as described below directly
        from you or from third party sources such as your employer, business
        partners, or publicly available information, as well as through the
        use of cookies, web beacons or other tracking technologies.
        </p>
        <p>
        Below we describe the types of personal information we may collect:
        </p>
        <p>
        <strong>Information you provide. </strong>If you are a general (i.e.
        non-verified) user of the COVID Network, we may collect Personal
        information about you directly during your use of the COVID Network,
        including, but not limited to, your email address, your contact
        information, and information you provide relating to the medical
        supply needs of your workplace.
        </p>
        <p>
        If you are an individual or entity seeking to become a Verified
        Requestor, we collect personal information about you directly during
        your use of the COVID Network, including, but not limited to, your
        email address, your contact information, information you provide
        relating to the medical supply needs of your workplace, your
        employment title, a photograph of your workplace ID and a photograph
        of your government issued ID. Verified users also must establish an
        account, in which we will also collect your COVID Network username,
        and the password associated with your COVID Network account.
        </p>
        <p>
        If you are an individual or an entity seeking to become a Verified
        Aid Organization, we may collect personal information about you
        directly during your use of the COVID Network, including, but not
        limited to, your email address, your contact information,
        information you provide relating to the aid that you or your
        organization may render, your employment title, a photograph of your
        workplace ID and a photograph of your government issued ID. We will
        also collect your Network username, and the password associated with
        your Network account.
        </p>
        <p>
        <strong>Automatically-collected information. </strong>We may also
        automatically collect certain information about your use of the
        COVID Network. This automatically collected information may include
        certain technical and usage information when you use the COVID
        Network, such as the type of device, browser, and operating system
        you are using, your Internet service provider or mobile carrier,
        unique device identifier, IDFA or IDFV, MAC address, IP address, GPS
        location, device and browser settings, and certain website usage
        information
        </p>
        <p>
        <strong>Location information.</strong> We may have access to certain
        information about your location, such as your work place address
        when you provide it, as well as location information derived from
        device information (such as an IP address and/or GPS coordinate
        location).
        </p>
        <p>
        <strong>Biometric Information.</strong> We do not collect biometric
        information and ask you to take care to not submit biometric
        identifiers when uploading your identification for the verification
        process. For example, if you must upload a work ID that includes
        your fingerprint, please redact or cover the fingerprint when you
        scan the ID.
        </p>
        <p>
        <strong>Sensitive Information.</strong> We ask that you refrain from
        submitting any sensitive personal information, third party
        confidential information, or other information that you are
        otherwise prohibited from sharing. For example, while there may be
        free-text opportunities to better describe the medical supply needs
        of your workplace, please do not provide information that could
        potentially be used to identify patients or otherwise constitute
        protected health information.
        </p>
        <h1>HOW WE USE THE INFORMATION</h1>
        <p>
        We collect your personal information to help us design and deliver
        our services, including the COVID Network.
        </p>
        <p>
        <strong>Your Use of the COVID Network. </strong>We may use your
        personal information to provide you with the services or information
        you have requested, to personalize your visit to the COVID Network
        and to assist you while you use the COVID Network, to provide
        support and to respond to your requests, complaints and enquiries,
        to keep a record of your relationship with us, to manage events and
        to further our charitable objectives, and to monitor, maintain and
        improve the processes, information and data, technology and
        communications solutions and services we use. We may also use this
        information to create aggregate data about our users and to perform
        aggregate data analytics.
        </p>
        <p>
        <strong>Individuals registering as Verified Requestors.</strong> In
        addition to the above, we may use your personal information to
        process your request for verification, to keep a record of Verified
        Requestors&rsquo; requests for medical supplies and equipment via
        the COVID Network, and to update Verified Requestors with important
        administrative information about your requests and responses, if
        applicable. We may also use this information to create aggregate
        data about our users and to perform aggregate data analytics,
        including information that helps us monitor and track medical supply
        needs and trends across facilities, networks, and geographic
        regions.
        </p>
        <p>
        <strong>
            Individuals registering as Verified Aid Organizations.
        </strong>{' '}
        In addition to the above, we may use your personal information to
        process your request for verification, to process Suppliers&rsquo;
        responses to requests for medical supplies and equipment made by
        Verified Requestors via the COVID Network, to keep a record of
        Suppliers&rsquo; responses to requests for medical supplies and
        equipment made by Verified Requestors via the COVID Network, to keep
        a record of Suppliers&rsquo; requests and Verified Requestors
        responses, to update Suppliers with important administrative
        information about your requests and responses, if applicable. We may
        also use this information to create aggregate data about our users
        and to perform aggregate data analytics.
        </p>
        <p>
        <strong>
            Compliance with law, or to protect our rights and interests, or
            for other public health and safety purposes.
        </strong>{' '}
        We may use personal information we collect to detect, investigate,
        and prevent activities on our sites that may violate our terms of
        use, could be fraudulent, violate copyright or other rules or that
        may be otherwise illegal, to comply with legal requirements, and to
        protect our rights and the rights and safety of our users and
        others, as well as to help improve the security and operation of the
        COVID Network.
        </p>
        <h1>HOW WE SHARE AND DISCLOSE THE INFORMATION</h1>
        <p>
        We may share and disclose personal information in the following ways
        or for any other purpose disclosed at the time of collection:
        </p>
        <p>
        <strong>Network Participants.</strong> We may share the personal
        information that we collect with other participants in the COVID
        Network, including Verified Requestors and Aid Organizations (which
        may include Public Health Authorities or other Government Agencies).
        </p>
        <p>
        <strong>Service Providers.</strong> We may share the personal
        information we collect with service providers that assist us with
        the verification process and as necessary to maintain the
        functionality of the platform.
        </p>
        <p>
        <strong>Legal and law enforcement purposes.</strong> We may disclose
        your personal information in response to legal process, for example
        in response to a court order or a subpoena, or in response to a law
        enforcement agency's request. We also may disclose personal
        information to third parties: (i) in connection with fraud
        prevention activities, (ii) where we believe it is necessary to
        investigate, prevent, or take action regarding illegal activities,
        (iii) in situations that may involve violations of our terms of use
        or other rules, (iv) to protect our rights and the rights and safety
        of others, and (v) as otherwise required by law.
        </p>
        <p>
        <strong>Change of control.</strong> We may transfer your Information
        in the event of a business transaction, such as if we or one of our
        business units or our relevant assets are acquired by, sold to, or
        merged with another company or as part of a bankruptcy proceeding or
        a business reorganization.
        </p>
        <p>
        We do not sell personal information. However, we reserve the right
        to share anonymized or aggregate data with our data partners, public
        health or other governmental sources, other organizations or even
        the public.
        </p>
        <h1>COOKIES AND OTHER TRACKING TECHNOLOGIES</h1>
        <p>
        We may send “cookies” and other tracking technologies to your
        computer or use similar technologies to understand and enhance your
        online experience with the COVID Network, to help recognize your
        browser or device, maintain your preferences, provide certain
        Network features, and collect and analyze information about your
        interactions with the COVID Network. Cookies are small text files
        placed in your browser. By visiting the COVID Network, whether as a
        verified user or otherwise, we may track your activities and your
        use of the COVID Network through the technologies described above,
        as well as similar technologies developed in the future. You may set
        your browser to reject cookies, but certain features on our site and
        applications may not work as intended. Please note that we do not
        respond to browser do not track signals.
        </p>
        <h1>YOUR DATA RIGHTS</h1>
        <p>
        Depending on the applicable law of the jurisdiction in which you
        reside, you may have certain rights relating to the collection and
        processing of your data. These rights may include the right to
        access the information collected about you, the right to delete
        information collected about you, the right to limit certain data
        sharing or opt-out of the sale of your personal information, as well
        as the right not to be discriminated against for exercising your
        rights. But, please note that such rights may not be applicable to
        the Helena Group Foundation as a non-profit organization, and/or
        also may not apply to the nature of the data that we collect, which
        relates only to business contact information. We do not use or
        disclose your information other than as described in this Privacy
        Policy, and as mentioned above, we do not sell personal information
        to third parties. If you wish to make any requests, or alert us to
        any concerns, about your personal information or our data practices,
        please contact us using the contact information below. Please note
        that we may need to verify your identity and the validity of the
        request prior to responding.
        </p>
        <h1>INFORMATION SECURITY</h1>
        <p>
        We have put in place reasonable security controls designed to help
        safeguard the personal information we collect via the COVID Network
        and retain your personal information as long as it is necessary and
        relevant for the operations of the COVID Network or for other
        legitimate purposes, including research and data analysis. However,
        no security measures are perfect, and we cannot assure you that the
        personal information that we collect will never be accessed or used
        in in an unauthorized way. Additionally, certain features may allow
        you to communicate with other COVID Network participants, however,
        please remember that we do not control and are not responsible for
        the activities of any COVID Network participants, and communications
        cannot be guaranteed to be anonymous. You should take precautions
        whenever sharing personal information with third parties or
        providing information over the Internet.
        </p>
        <h1>INFORMATION REGARDING CHILDREN'S PRIVACY</h1>
        <p>
        This website is not designed for use by anyone under the age 18
        years of age, and we do not otherwise knowingly collect the personal
        information of children.
        </p>
        <h1>LINKS TO OTHER WEBSITES</h1>
        <p>
        This Privacy Policy applies only to the COVID Network. Our Network
        may contain links to other websites that are not operated or
        controlled by the Helena Group Foundation. The Helena Group
        Foundation does not control such third-party websites or their
        privacy practices. Any personal data you choose to give to
        third-party websites is not covered by this Privacy Policy.
        </p>
        <h1>
        VISITORS TO THE COVID NETWORK FROM OUTSIDE OF THE UNITED STATES
        </h1>
        <p>
        The COVID Network is not intended for visitors outside of the U.S.
        If you are visiting the site or using our applications from outside
        of the U.S., your connection will be through servers located in the
        U.S. All information you provide through the COVID Network will be
        transferred, located and maintained on web servers within the U.S.
        Please be aware that the data protection laws in the U.S. may differ
        from those of the country in which you are located in. By using the
        COVID Network or by providing us with any personal information via
        the COVID Network, you consent to the processing and transfer of
        your personal information to the US.
        </p>
        <h1>CHANGES TO THE POLICY</h1>
        <p>
        We may change this Privacy Policy from time to time by posting a new
        version of this Privacy Policy on the COVID Network&rsquo;s website.
        We will notify you of any material changes via e-mail or through a
        notification on the COVID Network&rsquo;s website. Any changes to
        the Privacy Policy will become effective immediately after being
        posted. We encourage you to periodically review the Privacy Policy
        to stay informed of changes and on how we are protecting your
        personal data.
        </p>
        <h1>CONTACT US</h1>
        <p>
        If you have any questions about this Privacy Policy, please contact
        us using the following information:
        </p>
        <p>covidnetwork@helena.org</p>
        <h1>________________</h1>
        <h1>Last Updated April 9, 2020</h1>
        <h1>TERMS OF USE</h1>
        <p>This Terms of Use (“Terms”) is a legal agreement by and between each user of the Platform (“you”), both as an individual, and if applicable, as the entity you represent, and Helena Group Foundation (“Helena”, “we”, “us” or “our”),  which governs your access to and use of the COVID Network available at [INSERT WEBSITE LINK] (such platform and any successor thereto the “Platform”).</p>
        <p>Please read all these terms carefully. By accessing or using the Platform, and/or creating an account, you acknowledge that you have read, understand and agree to be bound by these Terms, which include our privacy policy [INSERT HYPERLINK]. [Additionally, before you create an account or register to use the Platform, you must check the box marked “I have read, and agree to, the Terms” and click “CONTINUE” to complete the registration process.]  If you do not accept the terms and conditions of the Terms, please do not use the Platform or create an account. </p>
        <p>NOTICE OF ARBITRATION AND CLASS-ACTION WAIVER</p>
        <p>These Terms contain a mandatory, binding arbitration clause and a waiver of class-action remedies in Section 17. You may opt out of arbitration by following the steps in Section 17.3. By accessing or using the Platform and/or creating an account, you: </p>
        <p>(A)	Acknowledge that you have read and understand the Terms;</p>
        <p>(B)	Represent that you are 18 years of age or older (and, if you are an employee or contractor of entity, that you have the authority to accept these on behalf of entity that you represent); and </p>
        <p>(C)	Accept these Terms and agree that you are legally bound by its terms including the mandatory, binding arbitration clause and class action waiver in Section 17.</p>
        <p>1.	About The Platform</p>
        <p>1.1.	The Platform aims to facilitate the provision of aid from certain accepted suppliers, charitable suppliers and other relief organizations (each, an “Organization”) in response to requests for aid related to COVID-19, including medical supplies, (each, a “Request”) made by healthcare facilities and organizations, healthcare workers and first responders (each, a “Requestor”). Helena does not endorse, certify or guarantee any Requestor or Organization, including the Requestor’s or Organization’s identity or background or whether such Requestor or Organization is trustworthy, safe or suitable. </p>
        <p>1.2.	As the provider and sponsor of the Platform, Helena does not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any medical supplies. Helena has no control over and does not guarantee (i) the existence, quality, safety, suitability, functionality, effectiveness, intended use, or legality of any medical supplies, (ii) the medical supplies’ compliance with the Federal Food, Drug, and Cosmetic Act, regulations issued by the U.S. Food and Drug Administration, or any other similar laws or regulations; (iii) the truth or accuracy of any posted Requests, (iv) the ability of Organizations to supply items; (v) the ability of a Requestor to pay for a particular item, if applicable, or (vi) that an Organization or Requestor will actually complete a transaction. </p>
        <p>1.3.	Helena may, from time to time and without prior notice, add, delete or change the features or functionality of the Platform. </p>
        <p>2.	Approval; Account Registration </p>
        <p>2.1.	You may create account on the Platform (“Account”). However, access to certain areas or features of the Platform is limited to Organizations and Requestors who have created an account and that have been approved by Helena, in its sole and absolute discretion (each, a “Registered Account”).</p>
        <p>2.2.	As part of the account creation process, you must provide accurate, current and complete information to Helena and keep this information up-to-date at all times. Some of the information that Helena may require as part of this process includes a copy of your workplace ID, government issued ID, workplace ID and/or email address. More information on how Helena uses this information can be found in our privacy policy. If you are creating an account for a company or other legal entity, you represent and warrant that you have the authority to legally bind that entity and grant Helena all permissions and licenses provided in these Terms.</p>
        <p>2.3.	You are responsible for all access to and use of the Platform through your account and/or credentials whether or not you have knowledge of, or authorize such access and use. You will notify Helena immediately of any unauthorized use of your account, credentials, or any other security breach of which you are aware. It is your sole responsibility to keep your account, credentials and other sensitive information confidential.</p>
        <p>3.	Requests and Responses </p>
        <p>3.1.	If you have an Account or a Registered Account, you may post a Request, which Helena, in its sole discretion may publish on the Platform. Helena may also elect to designate a Request as being made on behalf of a Registered Account. </p>
        <p>3.2.	If you have an Account, you will only be able to see limited details of Requests on the Platform; for example, you may be able to view photos of requested medical supplies without any other identifying information. If you have a Registered Account, you will have access to additional details associated with the Requests on the Platform, which may include the individual healthcare entity making the Request, the location of such healthcare entity, and other information related to the Request. </p>
        <p>3.3.	Helena may remove any Requests from the Platform at any time for any reason or no reason at all without notice to you.</p>
        <p>3.4.	If you have an Account or a Registered Account, you are permitted to message with other users on the Platform and engage in transactions. However, you may only initiate a message on the Platform if you have a Registered Account.</p>
        <p>4.	Additional Terms that Apply to You </p>
        <p>4.1.	You are solely responsible for making all arrangements necessary for you to have access to the Platform. Helena makes no representation that the Platform is appropriate or available for use in locations outside of the United States. If you access the Platform outside of the United States, you do so at your own risk. Whether inside or outside the United States, you are solely responsible for ensuring your own compliance with the laws of your specific jurisdiction(s).</p>
        <p>4.2.	By creating, uploading, posting, sending, receiving, storing, or otherwise making available any content, such as a Request, text, photos, audio, video, or other materials and information on or through the Platform (“Account Content”), you grant to Helena a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to such Account Content to access, use, store, copy, modify, prepare derivative works of, distribute, publish, transmit, stream, broadcast, and otherwise exploit in any manner such Account Content to provide and/or promote the Platform and facilitate the provision of medical supplies, in any media or platform. Unless you provide specific consent, Helena does not claim any ownership rights in any Account Content and nothing in these Terms will be deemed to restrict any rights that you may have.</p>
        <p>4.3.	You alone are responsible for identifying, understanding, and complying with all laws, rules and regulations that apply to your access and use of the Platform and any transactions resulting therefrom, including the federal Anti-Kickback Statute (42 U.S.C. § 1320a-7b(b)), the federal False Claims Act (31 U.S.C. §§ 3729 et seq.), and the Federal Food, Drug, and Cosmetic Act (21 U.S.C. Ch. 9, § 301 et seq.), that may arise from or relate to your use or access to the Platform or any transactions conducted through the Platform. You alone are responsible for identifying and obtaining any required licenses, permits, or registrations in connection with your access and use of the Platform and any transactions resulting therefrom. If you have questions about how laws and regulations apply to you, you should always seek legal guidance.</p>
        <p>4.4.	You agree that you will appropriately account for any healthcare items or services procured through the Platform in your claims or cost reports submitted to third-party payers, including federal healthcare programs, as applicable. </p>
        <p>4.5.	As noted in our Privacy Policy, you do not have to, and are prohibited from, providing protected health information as defined and protected by the Health Insurance Portability and Accountability Act, as amended (“HIPAA”), in conjunction with your use of the Platform. Your use of this Platform does not result in a business associate relationship with Helena, and you represent and warrant that you will not use the Platform for any purpose or in any manner involving the disclosure or transmission of protected health information to Helena or any other user of the Platform.</p>
        <p>4.6.	When you agree to a transaction, you are entering into a contract directly with the other party and both you and the other party shall be solely responsible for completing such transaction, including scheduling, processing, payment, shipping, and delivery logistics. You should always exercise due diligence and care when messaging and interacting with the other party and when handling any processing, payment, shipping, or returns, as applicable, whether online or in person. Helena is not and does not become a party to or other participant in any contractual relationship between you and the other party and Helena is not acting as an agent in any capacity for any party.</p>
        <p>4.7.	You acknowledge that Helena has no obligation to monitor the access to or use of the Platform or to review, disable access to, or edit any Account Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and support purposes); (ii) ensure compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to Account Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. You agree to cooperate with and assist Helena in good faith, and to provide Helena with such information and take such actions as may be reasonably requested by Helena with respect to any investigation undertaken by Helena or a representative of Helena regarding the use or abuse of the Platform.</p>
        <p>4.8.	You agree that any products sold or procured through the Platform will be used for the sole purpose of treating or preventing COVID-19, the transmission of SARS-CoV-2 or a virus mutating therefrom. </p>
        <p>5.	License To Use The Platform </p>
        <p>Subject to your compliance with these Terms, Helena grants you a limited, non-exclusive, non-transferable license to: (i) access and view the Platform and Requests made available on the Platform; (ii) post Requests, and (iii) message other users, in each case solely to the extent you are permitted to do so under your Account or Registered Account and for the purpose of making Requests and facilitating the provision of aid in response to the COVID-19 pandemic. You have no right to sublicense the license rights granted in this Section 5.</p>
        <p>6.	Prohibited Uses </p>
        <p>In connection with your use of the Platform, you will not and will not assist or enable others to: (i) breach or circumvent any applicable laws or regulations, agreements with third parties, third party rights, or these Terms; (ii) use the Platform for any unlawful, invasive, infringing, abusive, tortious, defamatory, libelous or fraudulent purpose; (iii) provide any information that is false or misleading, that attempts to hide your identity or that you do not have the right to disclose; (iv) copy, store, or otherwise access or use any information, including personally identifiable information, about any other user, contained on the Platform in any way that is inconsistent with Helena’s Privacy Policy or these Terms or that otherwise violates the privacy rights of others; (v) distribute or post spam, unsolicited or bulk electronic communications, chain letters, or pyramid schemes; (vi) use any robots, spider, crawler, scraper, or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose other than as expressly permitted under these Terms; (vii) distribute viruses or any other technologies that may harm the Platform, Helena or the interests or property of users; (viii) infringe the copyright, trademark, patent, publicity, moral, database, and/or other intellectual property rights that belong to or are licensed to Helena or any other third parties; (ix) circumvent any technical measures used to provide the Platform; (x) attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform; (xi) take any action that damages or adversely affects, or could damage or adversely affect the performance and proper functioning of the Platform; and/or (xii) engage in any other action that, in Helena’s judgment, exposes Helena or any third party to potential liability or detriment of any type. </p>
        <p>7.	Intellectual Property</p>
        <p>7.1.	The proprietary Helena content and content licensed or authorized for use by or through Helena from a third party (collectively, “Platform Content”), Platform, and any improvements, modifications and derivative works of the foregoing are owned exclusively by Helena, its licensors or other providers of such material and are protected by U.S. and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. Except for the licenses Helena expressly grants to you hereunder, you are not provided with any license or right to the Platform, Platform Content, or the intellectual property rights therein, whether by implication, estoppel or otherwise. In no event does Helena grant you any right to use any Helena trademark, service mark, logo or trade name.</p>
        <p>7.2.	Under no circumstances do these Terms or any provision hereunder convey any form of intellectual property rights to any governmental entity, including but not limited to any agency or instrumentality of the United States Government. For the avoidance of doubt, no Restricted Rights, Limited Rights, Government Purpose Rights, Specifically Negotiated License Rights, or Unlimited Rights to any technical data, computer software, or computer software documentation are conveyed under any provision of the Federal Acquisition Regulation (“FAR”), Department of Defense Supplement to the FAR (“DFARS”), or any other agency supplement to the FAR.</p>
        <p>8.	Feedback</p>
        <p>You may (but are not required to) provide Helena with suggestions, comments or other feedback regarding the Platform (“Feedback”). If you do provide Helena with Feedback, then Helena may, and you hereby grant Helena a non-exclusive, worldwide, royalty-free, irrevocable, sublicensable, perpetual license to, use, disclose, reproduce, publish, distribute and otherwise commercially exploit such Feedback for any purpose, without compensation to you. Any Feedback you submit to Helena will be considered non-confidential and non-proprietary to you.</p>
        <p>9.	Authorization to Contact You; Analyzing Message Content</p>
        <p>9.1.	Helena may contact you via the email address you used to register an account to: (i) authenticate your identity; (ii) facilitate communications; (iii) notify you regarding your account; (iv) troubleshoot problems with your account; (v) resolve a dispute; and (vi) as otherwise necessary to service your account or enforce these Terms, Helena’s policies, or applicable law. </p>
        <p>9.2.	Helena’s automated systems scan and analyze the contents of every message sent through the Platform, including messages between Requestors and Organizations, to detect and prevent fraudulent activity, violations of these Terms and any law or regulations, and to provide, monitor, maintain, and improve the Platform. The scanning and analysis may occur before, during, or after the message is sent, or while in storage, and may result in your message being delayed or withheld. Helena may store message contents, including to conduct this scanning and analysis.</p>
        <p>9.3.	Additional information pertaining to Helena’s collection, use, disclosure, retention, and protection of your personal information is governed by our privacy policy [INSERT HYPERLINK].</p>
        <p>10.	Modifications to the Terms</p>
        <p>Helena may, in its sole and absolute discretion, modify these Terms from time to time. If Helena modifies these Terms, Helena will provide you notice of the modification by posting the updated Terms on the Platform and Helena may, in its sole discretion, send notice of such modification to the email you provided to Helena. Changes take effect when Helena posts the modified Terms on the Platform. Your continued use of the Platform following notice of any such changes will constitute your acceptance of the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Platform. If you do not close your account and/or if you continue to use the Platform, you will be deemed to have accepted the changes. </p>
        <p>11.	Term </p>
        <p>The Terms will be effective upon the date you accept them and shall continue until terminated by either you or Helena as set forth herein. </p>
        <p>12.	Termination</p>
        <p>12.1.	Helena may, in its sole and absolute discretion and without limiting other remedies available to Helena, modify, suspend, terminate or discontinue your account and access to all or part of the Platform for any reason or no reason at all. You agree that Helena shall have no liability to you for any such modification, suspension, termination or discontinuation.</p>
        <p>12.2.	You may terminate these Terms at any time by sending an email to covidsupport@helena.org with the subject line “Account Cancellation”. If you cancel your account, any pending or confirmed transaction(s) will automatically be cancelled. </p>
        <p>13.	Confidential Information. </p>
        <p>You may be given access to confidential and proprietary information of other persons and entities (including Helena, Requestors and Organizations) (each, a “Disclosing Party”) through your use of and access to the Platform, Platform Content and/or Account Content. You may not disclose such confidential information to any third party without the written consent of the Disclosing Party. You must protect such confidential information with at least the same degree of care that is accorded to your confidential information, but in no event less than reasonable care. Confidential information does not include information that you can show (i) is known publicly through no breach of these Terms, (ii) was in its possession free of any obligation of confidence at the time of disclosure, (iii) was received from a third party free to disclose such information without restriction, or (iv) was independently developed without using or referring to the information of the disclosing party or breaching these Terms. You may make disclosures to the extent required by law or court order, provided you (i) notify the Disclosing Party in writing of such required disclosure as promptly as possible in order to permit such Disclosing Party to seek a protective order or other appropriate relief; (ii) cooperate with the Disclosing Party in such efforts, and (iii) use diligent efforts to limit such disclosure. Upon termination of these Terms or upon written request by a Disclosing Party, you will promptly return or destroy the confidential information and all copies, notes or extracts thereof. </p>
        <p>14.	Disclaimer of Warranties</p>
        <p>THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED BY HELENA TO THE EXTENT PERMITTED BY APPLICABLE LAW. HELENA MAKES NO REPRESENTATION OR WARRANTY THAT: (I) THE PLATFORM WILL BE SECURE OR AVAILABLE FOR USE AT ANY PARTICULAR TIME OR LOCATION; (II) YOUR USE OF THE PLATFORM WILL BE TIMELY, UNINTERRUPTED, ERROR-FREE OR OPERATE SUCCESSFULLY IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA; (III) ANY DEFECTS OR TECHNICAL ERRORS ON THE PLATFORM WILL BE IDENTIFIED AND, EVEN IF IDENTIFIED, CORRECTED; OR (IV) ANY CONTENT MADE AVAILABLE ON THE PLATFORM FOR ACCESS OR DOWNLOAD WILL BE FREE OF VIRUSES, MALWARE OR OTHER HARMFUL COMPONENTS OR DESTRUCTIVE CODE. YOUR USE OF THE PLATFORM IS SOLELY AT YOUR OWN RISK AND MAY BE SUBJECT TO LIMITATIONS, DELAYS AND OTHER PROBLEMS INCIDENTAL TO, OR INHERENT IN, THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. HELENA IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES OR OTHER DAMAGES RESULTING FROM ANY SUCH PROBLEMS.</p>
        <p>15.	Limitation of Liability</p>
        <p>15.1.	TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL HELENA OR ITS OFFICERS, EMPLOYEES, DIRECTORS, AGENTS OR LICENSORS BE LIABLE UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY DAMAGES WHATSOEVER, INCLUDING INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR SERVICE INTERRUPTION OR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM YOUR USE OF THE PLATFORM. NOTWITHSTANDING THE FOREGOING, IF HELENA IS FOUND TO BE LIABLE, LIABILITY OF HELENA AND ITS OFFICERS, EMPLOYEES, DIRECTORS, AGENTS OR LICENSORS TO YOU OR TO ANY THIRD PARTY IS LIMITED TO TEN U.S. DOLLARS ($10). </p>
        <p>15.2.	SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF WARRANTIES OR EXCLUSION OF DAMAGES, SO SUCH DISCLAIMERS AND EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, THE SCOPE AND DURATION OF OUR WARRANTIES AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER SUCH APPLICABLE LAW.</p>
        <p>16.	Indemnification</p>
        <p>You shall indemnify, defend, and hold harmless Helena and its affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, from or arising out of, relating to or incurred as a result of (i) your violation of these Terms and/or our Privacy Policy; (ii) your acts and omissions in connection with your use of the Platform; (iii) your violation of applicable laws and regulations; and/or (iv) any infringement by you of any intellectual property or other right of any person or entity. Helena reserves the exclusive right to defend and control (including to settle or agree to such other terms as we may determine) any such indemnification matters, and you agree fully to cooperate with us in any such defense, settlement and/or agreement.</p>
        <p>17.	Arbitration</p>
        <p>17.1.	Please read this Section carefully. It affects your legal rights. It provides for resolution of disputes through individual arbitration instead of court trials and class actions. Arbitration is more informal than a lawsuit in court, uses a neutral arbitrator instead of a judge or jury, and discovery is more limited. Arbitration is final and binding and subject to only very limited review by a court. This arbitration clause shall survive termination of these Terms and shall apply to all disputes whether arising before, during, or after the termination of these Terms.</p>
        <p>17.2.	Contact Information. Helena may contact or notify you for the purposes described in these Terms through the Platform, or using any contact information you have supplied in connection with the Software. If you have a complaint or concern regarding these Terms or the Platform, or for more information, please contact Helena at covidsupport@helena.org or by mail at the following address:</p>
        <p>[PROVIDE MAILING ADDRESS]</p>
        <p>17.3.	Opting Out of Mandatory Arbitration. You have a right to opt out of the agreement to arbitrate in Sections 17.4 through 17.6, by unambiguously notifying Helena in writing at the address above, Attn: [CONTACT NAME] - Helena Arbitration Opt-Out no later than sixty (60) days of the date you first accepted these Terms that you opt out of mandatory arbitration under these Terms. Unless you validly opt out as provided in this Section 17, the arbitration requirements in Sections 17.4 through 17.6, will remain effective, even if these Terms are terminated.</p>
        <p>17.4.	Notice of Claim. For all disputes arising out of or relating in any way to the Platform or these Terms (including any dispute about arbitrability itself), you must first send a written description of your claim to Helena to allow Helena an opportunity to resolve the dispute. You and Helena each agree to negotiate your claim in good faith. If the parties cannot resolve the dispute within sixty (60) days of written notice of a claim, then either party may request arbitration.</p>
        <p>17.5.	Arbitration Procedures. The individual arbitration of any dispute or claim arising out of or relating in any way to the Platform shall be conducted in accordance with the rules of the American Arbitration Association (“AAA”), including the AAA’s Consumer Arbitration Rules (as applicable), as modified by these Terms. For clarity, disputes concerning the interpretation or enforceability of this arbitration agreement shall also be subject to arbitration under this Section. The AAA Consumer Arbitration Rules and information about arbitration and fees are available online at www.adr.org. You agree that these Terms evidence a transaction in interstate commerce and this arbitration provision will be interpreted and enforced in accordance with the U.S. Federal Arbitration Act and federal arbitration law, and not governed by state law. Any arbitration shall take place in the County of Los Angeles. The individual arbitration will be conducted in the English language. An arbitrator may award on an individual basis any relief that would be available in a court, including injunctive or declaratory relief to the extent required to satisfy your individual claim, and must follow and enforce these Terms as a court would. Any arbitration shall be confidential, and neither party may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement of the arbitration award. Judgment on any arbitration award may be entered in any court having proper jurisdiction. </p>
        <p>17.6.	Costs. Each party will bear the fees and expense of its own attorneys, experts, witnesses and preparation and presentation of evidence at the arbitration. Each party will equally share all filing, administration and arbitrator fees. </p>
        <p>17.7.	Class Action Waiver for Arbitration. You agree that any proceeding in arbitration will be conducted only on an individual basis and not in a class, consolidated, or representative action.</p>
        <p>17.8.	Governing Law. By using the Platform, you agree that the statutes and laws of the United States and the State of California, without regard to conflicts of laws principles, will apply to all matters relating to use of the Platform. If the arbitration agreement in Sections 17.4 through 17.6 does not apply to a given dispute or claim, you agree that any litigation of that dispute or claim shall be subject to the exclusive jurisdiction of the state or federal courts in the County of Los Angeles, California, United States. The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from application to these Terms.</p>
        <p>17.9.	Class Action Waiver and Jury Waiver in Litigation. You agree that, if you have validly opted out of arbitration pursuant to Section 17.3, or if the arbitration agreement contained in Sections 17.4 through 17.6, is found to be unenforceable, any claim or proceeding to be pursued in court will be conducted only on an individual basis and not in a class, consolidated, or representative action. You further agree that if a claim or proceeding proceeds in court rather than through arbitration, you and Helena each waive the right to a jury trial.</p>
        <p>17.10.	Time Limit for Filing Claims. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to any use of the Platform or any activity related to these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. </p>
        <p>18.	Third-Party Links </p>
        <p>The Platform may contain links to third party websites or resources (“Third-Party Services”). Such Third-Party Services may be subject to different terms and conditions and privacy policies. Helena is not responsible or liable for the availability or accuracy of such Third-Party Services, or the content, products, or services available from such Third Party Services. Links to such Third-Party Services are not an endorsement by Helena of such Third-Party Services. </p>
        <p>19.	Notice and Procedures for Making Claims of Copyright or Intellectual Property Infringement </p>
        <p>Helena, in its sole discretion, may disable and/or terminate use by users who infringe, misappropriate or violate the intellectual property rights of others. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have otherwise been violated, please provide to Helena a notice containing the following information: (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest; (ii) a description of the copyrighted work or other intellectual property that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Platform (providing URL(s) in the body of an e-mail is the best way to help us locate content quickly); (iv) your name, address, telephone number and e-mail address; (v) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or other intellectual property right owner, its agent or the law; and (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or other intellectual property right owner or authorized to act on the copyright or intellectual property right owner’s behalf. For notice of claims of copyright or other intellectual property right infringements, Helena can be reached by sending a claim to [PROVIDE EMAIL]. </p>
        <p>20.	General</p>
        <p>20.1.	Entire Agreement. Except as they may be supplemented by a document referenced and incorporated herein or by additional Helena policies, guidelines, standards, or terms for a specific product, feature, service or offering, these Terms constitute the entire agreement between Helena and you regarding the Platform, including your access to and use of the Platform and any Requests made via the Platform. These Terms supersede all prior or contemporaneous agreements, proposals, understandings and communications between the parties with respect to the subject matter hereof, whether oral or written.</p>
        <p>20.2.	Assignment. Helena may assign or transfer these Terms, at its sole discretion, without restriction or your consent. You may not assign or transfer these Terms, by operation of law or otherwise, without Helena’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. </p>
        <p>20.3.	Force Majeure. If either party is unable to perform any of its obligations under these Terms because of severe weather, natural disasters, acts of God, pandemics, quarantines, orders or actions of or by a government entity or body, wars, riots, civil disorders, rebellions or revolutions, failure of telecommunications carriers, or other events beyond Helena’s reasonable control, then Helena is relieved from its performance of such obligations to the extent, and for the duration, that such performance is prevented by such events. </p>
        <p>20.4.	Relationship of the Parties. No joint venture, partnership, employment, or agency relationship exists between you and Helena as a result of the Terms or your use of the Platform.</p>
        <p>20.5.	Remedies. The Terms do not and are not intended to confer any rights or remedies upon any person other than the parties.</p>
        <p>20.6.	Severability. If any provision of these Terms, or the application of any provision to any person or circumstance, shall be held to be inconsistent with any present or future law, ruling, rule, or regulation of any court or governmental or regulatory authority having jurisdiction over the subject matter hereof, such provision shall be deemed to be rescinded or modified in accordance with such law, ruling, rule, or regulation, and the remainder of these Terms, or the application of such provision to persons or circumstances other than those as to which it has been held inconsistent, shall not be affected thereby.</p>
        <p>20.7.	No Waiver. Helena’s failure or delay to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise permitted under law. </p>
        <p>20.8.	Survival. Sections 7 (Intellectual Property), 8 (Feedback), 13 (Confidential Information), 14 (Disclaimer of Warranties), 15 (Limitation of Liability), 16 (Indemnification), 17 (Arbitration), and 20 (General) survive any termination of these Terms.</p>
        <p>20.9.	Interpretation. The section headings in these Terms are for convenience and reference only and have no legal or contractual effect. No presumption is to operate in either party’s favor as a result of who drafted these Terms. For purposes of these Terms, the words “include,” “includes” and “including” are deemed to be followed by the words “without limitation,” and the word “or” is not exclusive.</p>
        <p>21.	Contacting Helena</p>
        <p>All feedback, comments, and other communications relating to the Platform and/or any Platform Content should be directed to covidsupport@helena.org.</p>

    </div>
  
  )
})

export {TermsAndPrivacy}
