import React from 'react'
import { observer } from 'mobx-react'
import { stateStore } from '../../store'

const LayoutOfferAidLink = observer(() => (
  <li>
    <button
      onClick={() => {
        stateStore.currentModal = 'ProviderSignup'
      }}
    >
      <img src="img/red-heart.svg" alt="Heart" /> GIVE AID
    </button>
  </li>
))

export {LayoutOfferAidLink}
