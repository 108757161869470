import { useQuery } from '@apollo/react-hooks'
import { Descriptions,Button, Col, List, Row, Select, Spin} from 'antd'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import {
    SEARCH_REQUESTS,
    GET_ITEMS,
    SEARCH_REQUESTS_COUNT

} from '../../queries/RequestList.gql'
import { authStore, stateStore } from '../../store'
import { NoRequestsAvailable } from './NoRequestsAvailable'
import { RequestItem } from './RequestItem'
import {UserById} from '../../queries/UserById.gql'
import { auth } from 'firebase'


const LoadingSpinner = (props) => {
  return (
    <div style={{
        textAlign:"center"
      }}>
      <Spin />
    </div>
  )
}

const RequestList = observer(() => {

  // TODO: actually measure element
  // const limit = Math.floor(100 / useWindowSize().height)

  // This loads the user requests, i.e. the data to be populated in this list.


  const getItemQuery = useQuery(GET_ITEMS)
  const items = toJS(stateStore.selectedItemFilters)
  const counties = toJS(stateStore.selectedCounties)
  const states = toJS(stateStore.selectedStates)
  const cities = toJS(stateStore.selectedCities)
  const facilities = toJS(stateStore.selectedFacilities)

  let searchVariables = {variables : {}}
  if (items.length > 0) {
    searchVariables.variables['items'] = items
  }



  if (counties.length > 0 || states.length > 0 || cities.length > 0 || facilities.length > 0) {
    searchVariables.variables['county_ids'] = counties
    searchVariables.variables['state_names'] = states
    searchVariables.variables['city_ids'] = cities
  }

  if (authStore.isAuthorized && facilities.length > 0) { 
    searchVariables.variables['facility_names'] = facilities
  } else {
    searchVariables.variables['facility_names'] = []
  }

  console.log(searchVariables.variables)

 searchVariables.variables['auth'] = toJS(authStore.isAuthorized)

 const searchCountVariable = JSON.parse(JSON.stringify(searchVariables))
 searchVariables.variables['limit'] = 200

  const requestsQuery = useQuery(SEARCH_REQUESTS, searchVariables)
  const requestCountQuery = useQuery(SEARCH_REQUESTS_COUNT, searchCountVariable)
  const responderData = useQuery(UserById, { variables: { userId: authStore.userId } })


  const loading= getItemQuery.loading || requestsQuery.loading || responderData.loading 
  const error = getItemQuery.error || requestsQuery.error || (responderData.error && authStore.isAuthorized) || requestCountQuery.error// responder data only triggers an error if authenticated

  if (error) {
    console.log('query error!')
    console.log(authStore.isAuthenticated)
    console.log(authStore.isAuthorized)
    console.log(requestsQuery.error) 
    console.log(getItemQuery.error) 
    console.log(responderData.error)
    console.log(requestCountQuery.error)

    return <span>error</span>
  } else {

    const requestData = requestsQuery?.data?.request
    if (requestCountQuery?.data?.request_aggregate.aggregate) {
      stateStore.totalRequestsShown = requestCountQuery?.data?.request_aggregate.aggregate.count // set this to update requestdrawer icon

    }
    const responder = responderData?.data?.users[0]
    if (!loading && !error) {
      stateStore.updatedRequests = true
    } 
    const goodOut = (
        <>

            {loading ? (<LoadingSpinner />) : (<></>)}
            <List className="RequestList" style={{overflowY:"scroll"}}>
            {!authStore.isAuthorized && requestData?.map((r) => {
                return (
                <RequestItem
                    request={r}
                    key={r.request_id}
                    requestId={r.request_id}
                    amount={r.priority_score}
                    county={r.county?.county}
                    timestamp={r.timestamp}
                    item_type={r.item}
                />
                )
            })},
            {authStore.isAuthorized && requestData?.map((r) => {
                return (
                <RequestItem
                    request={r}
                    key={r.request_id}
                    requestId={r.request_id}
                    amount={r.priority_score}
                    county={r.county?.county}
                    timestamp={r.timestamp}
                    item_type={r.item}
                    responderOrganization={authStore.organization}
                    responderFirstName={authStore.firstName}
                    responderLastName={authStore.lastName}
                />
                )
            })}

            </List>

        {(requestData?.length) ? undefined : <NoRequestsAvailable loading={loading}></NoRequestsAvailable>}

      </>
    )
    return goodOut
  }
})

export { RequestList }
