import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { stateStore } from '../../store'
import '../../styles/Modal.css'

const RequestCompleteModal = observer((props) => {
  const history = useHistory()
  const width = props.width || 540
  const onCancel = props.onCancel
  const visible = props.visible || stateStore.currentModal === 'RequestComplete'
  const setVisible = (arg) => {
    if (arg) {
        stateStore.currentModal = 'RequestComplete'
    } else {
        stateStore.currentModal = ''
    }
  }
  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  return (
    <Modal
    centered
    width={width}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
  title= { 
        <> <Typography.Title level={1}>Thank you for your request</Typography.Title> 
        </>
        }
      visible={visible}
      onCancel={() => stateStore.currentModal = ''}
      onOk={() => stateStore.currentModal = ''} 
     > 
     
    </Modal>
)});

export {RequestCompleteModal}
