import React from 'react'
import { observer } from 'mobx-react'
import { stateStore, authStore } from '../../store'

const LayoutRequestAidLink = observer(() => (
  <li>
    <button
      onClick={() => {
        authStore.isAuthenticated ? stateStore.currentModal = 'RequestForm' : stateStore.currentModal = 'RequesterSignup' 
      }}
    >
      <img src="img/red-heart.svg" alt="Heart" /> GET AID
    </button>
  </li>
))

export {LayoutRequestAidLink}