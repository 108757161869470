import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { observer } from 'mobx-react'
import gql from 'graphql-tag'
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Modal,
  Typography,
} from 'antd'

import { authStore, stateStore } from '../../store/index.js'
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {toJS} from 'mobx'

import '../../styles/Modal.css'
import '../../styles/ModalForm.css'
import Item from 'antd/lib/list/Item'
import { REQUEST_MUT, AUTODATA_QUERY } from '../../queries/Report.gql.js'

const {Option} = Select

const dateFormatter = new Intl.DateTimeFormat(void 0, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
})

/*
const importantStyles = {
  opacity: 0.5,
  fontSize: '0.9em',
  textTransform: 'uppercase',
}
*/



const unpipeItemId = (function () {
  const regex = /(?=.*|)\d+$/
  return function (str) {
    const [match] = str.match(regex)
    return match == null ? null : parseInt(match, 10)
  }
})()

const RequestForm = observer((props) => {
  const gutter = props.gutter || 24
  const date =
    props.date instanceof Date
      ? props.date
      : props.date != null
      ? new Date(props.date)
      : new Date()

  const [form] = Form.useForm()

  const autoData = useQuery(AUTODATA_QUERY)
  const [insertRequest, requestStatus] = useMutation(REQUEST_MUT, {
    onCompleted(data) {
      typeof props.onFinish === 'function' && props.onFinish(data)
    },
    onError(err) {
      // TODO: better handle errors
      console.error(err)
    },
  })

  if (autoData.loading) {
    return (
      <div className="Modal-zebra">
        <Spin>Loading data …</Spin>
      </div>
    )
  } else if (autoData.error) {
    console.error(autoData.error)
    return (
      <div className="Modal-zebra">
        <span>ERROR</span>
      </div>
    )
  } else {
    const { hospital } = autoData.data

    // categorizedItems ∷ Array ( String, Array { id: Int, name: String } )
    const categorizedItems = Object.entries(
      (autoData.data.item || []).reduce((dict, item) => {
        const item_ = { id: item.item_id, name: item.item_name }
        const category = item.item_category.trim()
        if (category in dict) {
          dict[category].push(item_)
        } else {
          dict[category] = [item_]
        }
        return dict
      }, {})
    )

    // TODO: no fallback
    const email = authStore.email || 'email address'

    const handleFinish = (formData) => {
      formData.timestamp = new Date().toISOString()
      //formData.item_list = JSON.stringify(formData.item_list.map(unpipeItemId))
      formData.email = email // TODO: needed??
      const [hospitalName, hospitalID, countyID] = formData.hospital_data.split('|')
      //const itemList = formData.item_list.map(item => item.split('|')[0])
      const requestList = formData.item_list.map((item) => {
            return {
            'hospital_id': parseInt(hospitalID),
            'item': item.item_type.split('|')[0],
            'item_quantity' : item.item_quantity,
            'request_text': formData.request_text,
            'timestamp': formData.timestamp,
            'cases': formData.cases,
            'capacity': formData.capacity,
            'hospital_name': hospitalName,
            'county_id': countyID,
            'organization_role' : formData.role,
            'user_id': toJS(authStore.userId),
            'email' : toJS(authStore.email)
        }
      })
      insertRequest({ variables: { objects: requestList } })
    }

    const itemOptGroup = ([category, items], idx) => (
      <Select.OptGroup key={category} label={category}>
        {items.map(itemOption)}
      </Select.OptGroup>
    )


    // value is a hack, because the search only reads the value, NOT the label
    const itemOption = (item) => (
      <Select.Option key={item.id} value={`${item.name}|${item.id}`}>
        {item.name}
      </Select.Option>
    )

    const hospitalOption = (h) => (
      <Select.Option key={h.hospital_id} value={ `${h.hospital_name}|${h.hospital_id}|${h.county_id}`}>
        {`${h.hospital_name} (${h.address})`}
      </Select.Option>
    )

    return (
      <Form
        name="report"
        className="ModalForm"
        layout="vertical"
        form={form}
        colon={false}
        onFinish={handleFinish}
      >
        <fieldset disabled={requestStatus.loading}>
          {/* I can't think of an element that makes sense here. I'd just use grid but that'll be out of the browser support matrix. */}
          {/*
          <legend className="ModalForm-legendTable">
            <table>
              <thead>
                <tr>
                  <th>Request for aid</th>
                  <th>Requested</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{'TODO: THING'}</td>
                  <td>
                    <time datetime={date.toISOString()}>
                      {dateFormatter.format(date)}
                    </time>
                  </td>
                </tr>
              </tbody>
            </table>
          </legend>
          */}
          <div className="Modal-zebra">
            <Row gutter={gutter}>
              <Col span={24}>
                <Form.Item
                  name="cases"
                  label="How many COVID-19 patients confirmed and suspected, are you treating now?"
                >
                  <InputNumber min={0} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="Modal-zebra">
            <Row gutter={gutter}>
              <Col span={24}>
                <Form.Item
                  name="capacity"
                  label="How many COVID-19 patients do you have the capacity to treat safely at this moment?"
                >
                  <InputNumber min={0} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          
          <div className="Modal-zebra">
          <Form.Item label="What do you need in order to safely treat more patients? Add items in order from most to least needed." rules={[{required : true}]}> 
          <Form.List name="item_list">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <>
                    <Row key={field.key} gutter={gutter}>
                  
                        <Col span={24}>
                        <Form.Item
                          name={[field.name, "item_type"]}
                        
                          rules={[{'required' : true}]}
                        >
                          <Select
                            placeholder="What do you need?"
                          >
                            {categorizedItems.map(itemOptGroup)}
                          </Select>
                          
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row key={field.key} gutter={gutter}>
                      <Col span={24}>
                        <Form.Item
                          name={[field.name, "item_quantity"]}
                          
                          rules={[{'required' : true}]}
                        >
  
                            <Input placeholder="How much do you need?" />
 
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row key={field.key} gutter={gutter}>
                      <Col span={24}>
                        <Button 
                          onClick={() => {
                              remove(field.name);
                            }}
                          style={{
                            width:"40%",
                            marginLeft: "30%",
                            marginRight: "30%",
                            marginBottom:"20px"
                          }}
                          type="dashed"
                        >
                          Remove item
                        </Button>
                      </Col>
                    </Row>

                      <hr></hr><br></br>
                   
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{ width: "100%" }}
                    >
                      <PlusOutlined /> Add item
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          
          </Form.List>          
          </Form.Item>
            
          </div>

          <div className="Modal-zebra">
            <Row gutter={gutter}>
              <Col span={24}>
                <Form.Item
                  name="request_text"
                  label="Anything else we can help with?"
                  rules={[]}
                >
                  <Input.TextArea
                    placeholder="Share how you intend to address this request"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="Modal-zebra">
            <Row gutter={gutter}>
              <Col span={24}>
                <Form.Item name="email" label="Email">
                  <Input
                    type="email"
                    placeholder="name@domain.com"
                    value={email}
                    defaultValue={email}
                    readOnly
                    disabled
                  />
                </Form.Item>
    
                <Form.Item
                  name="hospital_data"
                  label="Institution"
                  rules={[{ required: true }]}
                >
                  <AutoComplete
                    placeholder="Where do you work?"
                    filterOption={(inputValue, option) =>
                      option?.children
                        .toUpperCase()
                        .includes(`${inputValue}`.toUpperCase()) ||
                      `${option?.value}`.includes(`${inputValue}`)
                    }
                  >
                    {hospital.map(hospitalOption)}
                  </AutoComplete>
                </Form.Item>
                <Form.Item name="role"  label="Role" rules={[{required : true}]}>
                  <Select placeholder="What's your role?">
                    <Option value="medical_provider">HEALTHCARE PROVIDER (E.G. PHYSICIAN, NURSE)</Option>
                    <Option value="executive">HEALTHCARE EXECUTIVE</Option>
                    <Option value="procurement">PROCUREMENT STAFF</Option>
                    <Option value="other">OTHER</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </fieldset>

        <div className="ModalForm-footer">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={requestStatus.loading}
            >
              Request aid
            </Button>
          </Form.Item>
        </div>
      </Form>
    )
  }
})

const RequestFormModal = observer((props) => {
  const width = props.width || 800
  const onFinish = props.onFinish
  const onCancel = props.onCancel

  const visible = props.visible || stateStore.currentModal === 'RequestForm'
  const setVisible = (arg) => {
    if (arg) {
        stateStore.currentModal = 'RequestForm'
    } else {
        stateStore.currentModal = ''
    }
  }

  function handleFinish(formData) {
    typeof onFinish === 'function' && onFinish(formData)
    setVisible(false)
    console.log(formData)
    stateStore.currentModal = 'RequestComplete'
  }

  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  return (
    <Modal
      centered
      visible={visible}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
      width={width}
      onCancel={handleCancel}
      title={
        <>
          <Typography.Title level={1}>We’re here to help</Typography.Title>
          <Typography.Paragraph>
            We are helping to route aid and support throughout the country so
            that people working on the front lines have what they need to
            thrive.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Not working at a hospital or care facility? Send this page to the
            healthcare professionals in your life to get them the support they
            need.
          </Typography.Paragraph>
        </>
      }
      footer={null}
    >
      <RequestForm onFinish={handleFinish} />
    </Modal>
  )
})

export {RequestFormModal}
