import StateStore from './stateStore'
import AuthStore from './authStore'
import { create } from 'mobx-persist'
import { toJS } from 'mobx'

const stateStore = new StateStore()

const authStore = new AuthStore()

const hydrate = create()
hydrate('auth', authStore)

export { stateStore, authStore }
