import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

import { App } from './components/App'
import * as serviceWorker from './serviceWorker'
import { authStore } from './store'
import { message } from 'antd'

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_HASURA_HTTP_URL ||
    'https://helena-covid-hasura-staging.herokuapp.com/v1/graphql',
})

// const wsLink = new WebSocketLink({
//   uri:
//     process.env.REACT_APP_HASURA_WS_URL ||
//     'wss://helena-covid-hasura-staging.herokuapp.com/v1/graphql',
//   options: {
//     reconnect: true,
//   },
// })

const authLink = setContext((_, { headers }) => {
  let token = authStore.token
  //console.log(`Bearer ${token}`)

  // Return headers to context so httpLink can read them.
  if (token) {
    return {
      headers: {
        ...headers,
        'authorization': 'Bearer ' + token
      }
    }
  } else {
    return headers
  }
}).concat(
  onError(({ response: { errors } }) => {
    if (errors.find((e) => e?.extensions?.code === 'access-denied')) {
      authStore.logout()
    }
    else if ((errors.find((e) => e?.extensions?.code === 'invalid-jwt' && e?.message?.includes('JWTExpired')))) {
      if (authStore.token) {
        console.log(errors.find((e) => e?.message?.includes('JWTExpired')))
        message.info("Your login session has expired. Please log back in", 10)
        authStore.logout()
      }
    }
    else if (errors) {
      console.log(errors)
      if (authStore.token) {
        console.log('Logging out with errors')
        authStore.logout()
      }
    }


  })
)

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     )
//   },
//   wsLink,
//   httpLink
// )

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
