import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Typography, Row, Col, Divider} from 'antd'

import { stateStore} from '../../store'
import { PATHS } from '../../constants'
import '../../styles/Modal.css'

const SignupCTAModal = observer((props) => {

  const width = props.width || 540

  function handleGoToRequester() {
    stateStore.currentModal = 'RequesterSignup'
    
  }
  function handleGoToProvider() {
    stateStore.currentModal = 'ProviderSignup'
  }


  return (
    <Modal
    centered
    width={width}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
  title= { 
        <> <Typography.Title level={1}>Join Forces</Typography.Title> 
        <Typography.Paragraph>
          <Divider></Divider>
        </Typography.Paragraph> 
        <Typography.Title level={4} style={{textAlign: 'justify'}}>
          Whether you're on the front lines in a hospital, a donor with masks to spare, or a government looking to coordinate action,
          we can help you connect with the right people.
          </Typography.Title>
          
          <Typography.Paragraph>
            <Divider></Divider>
          </Typography.Paragraph> 
          <Typography.Paragraph style={{textAlign: 'justify'}}>
          Are you a <b>healthcare professional</b> who requires additional resources? 
          Sign up as an <b>Aid Requester</b> to get help. 
          </Typography.Paragraph>
          <Typography.Paragraph style={{textAlign: 'justify'}}>
          Are you <b>looking to contribute resources </b>to assist in the efforts? 
          Apply to become an <b>Aid Provider</b> for access to better analytics and direct access to healthcare providers
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Divider></Divider>
          </Typography.Paragraph>
          <div style={{display:'flex', justifyContent:'space-around'}}>
            {/* <Row type="flex" gutter={2}> */}
              {/* <Col span={12}> */}
                <Button key="submit" type="secondary" onClick={handleGoToProvider}> Aid Provider</Button>
              {/* </Col> */}
              {/* <Col span={12}> */}
                <Button key="submit" type="secondary" onClick={handleGoToRequester}> Aid Requester</Button>
              {/* </Col> */}
            {/* </Row> */}
          </div>
          </>
        }
      visible={stateStore.currentModal === 'SignupCTA'}
      onCancel={() => stateStore.currentModal = ''}
      footer={[


      ]}
    >
      
    </Modal>
  )
})

export {SignupCTAModal}
