import gql from "graphql-tag"

export const AUTODATA_QUERY = gql`
  query getAutoData {
    item(order_by: { item_name: asc }) {
      item_category
      item_id
      item_name
    }
    hospital {
      address
      hospital_name
      hospital_id
      county_id
    }
  }
`

export const REQUEST_MUT = gql`
  mutation insert_multiple_request($objects: [request_insert_input!]!) {
    insert_request(objects: $objects) {
      affected_rows
    }
  }
`

