import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { stateStore } from '../../store'
import '../../styles/Modal.css'

const ProviderAccountReviewModal = observer((props) => {
  const history = useHistory()
  const width = props.width || 540
  const onCancel = props.onCancel
  const visible = props.visible || stateStore.currentModal === 'ProviderAccountReview'
  const setVisible = (arg) => {
    if (arg) {
      stateStore.currentModal = 'ProviderAccountReview'
    } else {
      stateStore.currentModal = ''
    }
  }
  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  return (
    <Modal
    centered
    width={width}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
  title= { 
        <> <Typography.Title level={1}>Aid Provider Review in Progress</Typography.Title> 
        <Typography.Paragraph>
          ___
        </Typography.Paragraph> 
          <Typography.Paragraph>
          Thanks for your offer to provide aid. For security reasons, we manually review all users who offer to provide aid to The COVID Network. We'll send you an email when our review is complete. If you have any questions, email will@helena.org
          </Typography.Paragraph>
          <Typography.Paragraph>
          ___
          </Typography.Paragraph> </>
        }
      visible={visible}
      onCancel={() => stateStore.currentModal = ''}
      footer={null}
     > 
     
    </Modal>
)});

export {ProviderAccountReviewModal}
