import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Typography, Form, Input } from 'antd'
import { useHistory } from 'react-router-dom'

import { stateStore } from '../../store'
import '../../styles/Modal.css'

const SendResponseEmailModal = observer((props) => {

  const [sent, setSent] = useState(false)

  const userId = stateStore.selectedRequestInformation.userId
  const requestId = stateStore.selectedRequestInformation.requestId
  const itemType = stateStore.selectedRequestInformation.itemType
  const responderFirstName = stateStore.selectedRequestInformation.responderFirstName
  const responderLastName = stateStore.selectedRequestInformation.responderLastName
  const responderOrganization = stateStore.selectedRequestInformation.responderOrganization
  const history = useHistory()
  const width = props.width || 540
  const onCancel = props.onCancel
  const visible = props.visible || stateStore.currentModal === 'SendResponseEmail'
  let fromString = (responderFirstName && responderLastName) ? 'from ' + responderFirstName + ' ' + responderLastName : ''
  if (fromString.length > 0) {
    fromString = (responderOrganization) ? fromString + ' at ' + responderOrganization : fromString
  }
  const setVisible = (arg) => {
    stateStore.currentModal = 'SendResponseEmail'
  }
  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  const submitInitialMessage = (e) => {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type' : 'application/json', 'x-api-key': process.env.REACT_APP_AWS_EMAIL_API_KEY},
      body: JSON.stringify({
        responderId: userId,
        requestId: requestId,
        emailBody: e.message,
        emailSubject: 'Response to Your Request #' + requestId + ' for Item ' + itemType + ' ' + fromString
      })
    }
    fetch('https://fiyxgzjib4.execute-api.us-east-1.amazonaws.com/default/initial-email', requestOptions).then(response => setSent(true))
  }

  return (
    <Modal
    centered
    width={width}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
  title= { 
    <>
    {!sent ? (
          <>
           <Typography.Title level={1}>Start a conversation with the person who made this request.</Typography.Title>
          <Typography.Text style={{textAlign:'justify', marginTop:'80px', marginBottom:'20px'}}>Enter a message to the Requester below, and we'll forward it to their email. Watch your email inbox for their response!</Typography.Text>

          <Form
            name="email"
            onFinish={submitInitialMessage}
            style={{background:'transparent'}}
          > 
            <Form.Item name="message" label="Message" rules={[{required: true}]}>
              <Input.TextArea rows={4} ></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send message
              </Button>
            </Form.Item>
          </Form>
        
        <Typography.Paragraph style={{textAlign:'justify', marginTop:'80px', marginBottom:'20px'}}>
            <p>Helena passes messages between Requester and Aid Providers, while preserving the confidentiality of both parties. <br></br></p>
            <p>This is a necessary step to enable open and secure communication, since some healthcare providers cannot disclose 
              their institution names.</p><br></br><br></br>
          </Typography.Paragraph>
          </>
    ) : (
      <Typography.Title level={1}>Thank you for offering aid. Keep an eye on your email for a response.</Typography.Title>
    )}
        </>
  }
      visible={visible}
      onCancel={() => stateStore.currentModal = ''}
      footer={[]

      }
     > 
     
    </Modal>
)});

export {SendResponseEmailModal}
