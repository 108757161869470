import React from 'react'
import { observer } from 'mobx-react'
import { stateStore, authStore } from '../../store'

const LayoutThankYouLink = observer(() => (
  <li>
    <button
      onClick={() => {
        stateStore.currentModal = 'ThankYou' 
      }}
    >
      COLLABORATORS
    </button>
  </li>
))

export {LayoutThankYouLink}