import { observable, action, computed } from 'mobx'
import {World, layerDict} from '../components/Map'
import { authStore } from '.'

class stateStore {

  //city ids of selected cities
  @observable selectedCities = []

  //Names of selected hospitals
  @observable selectedFacilities = [] 

  // Selected county IDs, string[]
  @observable selectedCounties = []
  
  // Selected state names, string[]
  @observable selectedStates = [] 

  // Selected item names, string[]
  @observable selectedItemFilters = []

  // Whether to show the requests drawer
  @observable drawerVisible = false

  // manually set observable that tracks the total number of requests shown, eliminating a query
  @observable totalRequestsShown = 0 

  // Dict of visible map layers, tying to whether or not they are showing
  // Initializes with the defaultOn parameter of layerDict
  @observable visibleMapLayers = Object.keys(layerDict).map((l) => [l, layerDict[l].defaultOn])
                                      .reduce((accum, val) => {
                                        accum[val[0]] = val[1]
                                        return accum
                                      }, {})
  
  // this gets populated when a request is selected from the request view. The email modal reads it
  // it includes userId, requestId, responderFirstName, responderLastName, and itemType
  @observable selectedRequestInformation = {}
  
  // '' for none, 'SendResponseEmail', 'RequestForm'
  // 'SignupCTA', 'RequesterSignup', 'ProviderSignup'
  // 'SignupConfirm', 'RequestComplete', 'Landing',
  // 'ProviderAccountReview', 'MapLegend', 'Thank You'
  @observable currentModal = !authStore.isAuthenticated ? 'Landing' : ''
  
  // sets layer controls open or close
  @observable layerControlsOpen = false

  // is there a fresh batch of requests ready?
  @observable updatedRequests = false

  // is the mobile menu open
  @observable mobileMenuOpen = false;

  // for when we move over to tabs instead of modals everywhere
  @observable tab = 0;

}

export default stateStore
