import React from 'react'
import { useHistory } from 'react-router-dom'
import { authStore } from '../../store'
import { PATHS } from '../../constants'
import { observer } from 'mobx-react'

const LayoutSignInOutLink = observer(() => {
  const history = useHistory()
  if (authStore.redirectToLogin()) {
    history.push(PATHS.login)
  }

  const handleSignIn = () => history.push(PATHS.login)
  return (
    <>
      {authStore.isAuthenticated ? (
        <li>
          <button onClick={authStore.logout}>
            <img src="img/sign-in.svg" alt="Sign Out" /> SIGN OUT
          </button>
        </li>
      ) : (
        <li>
          <button onClick={handleSignIn}>
            <img src="img/sign-in.svg" alt="Sign In" /> SIGN IN
          </button>
        </li>
      )}
    </>
  )
})

export {LayoutSignInOutLink}
