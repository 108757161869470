import { Menu, Badge, Row, Col, Layout, Dropdown } from 'antd'
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  UserOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { PATHS } from '../../constants'
import { authStore, stateStore } from '../../store'
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { GlobalSearchBar } from '../GlobalSearchBar'
// import '../../styles/custom/search.css'

const { SubMenu } = Menu

const Topbar = observer((props) => {
  const width = props.width
  const menuwidth = width * 0.8
  const history = useHistory()

  function handleClick(e) {
    console.log('click ', e['key'])
    if (e['key'] == 'signin') {
      history.push(PATHS.login)
    }
    if (e['key'] == 'logout') {
      authStore.logout()
    }
    if (e['key'] == 'aid') {
      stateStore.currentModal = 'RequestForm'
    }
    if (e['key'] == 'signup') {
      stateStore.currentModal = 'SignupCTA'
    }
    if (e['key'] == 'map') {
      stateStore.currentModal = 'MapLegend'
    }
    if (e['key'] == 'requests') {
      stateStore.drawerVisible = !stateStore.drawerVisible
      console.log(stateStore.drawerVisible)
    }
    if (e['key'] == 'helena') {
      var win = window.open(
        'https://helena.org/projects/covid-19-response',
        '_blank'
      )
      win.focus()
    }
    if (e['key']=='ty') {
      stateStore.currentModal = 'ThankYou' 
    }
  }


  const requests = (
    <Menu className="mobile-dropdown" onClick={handleClick}>
      <Menu.Item key="requests">
        <div className="dropdown">
         
          See Requests
        </div>
      </Menu.Item>
      <Menu.Item key="aid">
        <div className="dropdown">
          Make a Request
        </div>
      </Menu.Item>
    </Menu>
  )

  const info = (
    <Menu className="mobile-dropdown" onClick={handleClick}>
      <Menu.Item key="map">
        <div className="dropdown">About the Map</div>
      </Menu.Item>
      <Menu.Item key="ty">
        <div className="dropdown">Acknowledgments</div>
      </Menu.Item>
      <Menu.Item key="helena">
        <div className="dropdown">Learn More</div>
      </Menu.Item>
    </Menu>
  )

  const user = !authStore.isAuthenticated ? (
    <Menu className="mobile-dropdown" onClick={handleClick}>
      <Menu.Item key="signup">Sign Up</Menu.Item>
      <Menu.Item key="signin">Log In</Menu.Item>
      {/* <Menu.Divider /> */}
      {/* <Menu.Item key="3">CTA?</Menu.Item>> */}
    </Menu>
  ) : (
    <Menu onClick={handleClick}>
      <Menu.Item key="logout">
      <div className="dropdown">Log Out</div>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Layout.Header
        style={{ position: 'fixed', zIndex: 1, width: '100%'}}
      >
        <div className="mobile-top" style={{ width: '100%', paddingLeft: '1%', paddingRight: '3%' }}>
          <Row className="searchContainer">
            <Col span={4}>
              <Dropdown overlay={requests} trigger={['click']}>
                <div className="topmenubox">
                <Badge count = {stateStore.totalRequestsShown} overflowCount="5000"
                style= {{zIndex:'9999', backgroundColor:'#004799'}}>
                  <MedicineBoxOutlined style={{ fontSize: '22px' }} />
                  <div style={{fontSize:'12px'}}>
                  Requests
                  </div>
                  </Badge>
                </div>
              </Dropdown>
            </Col>
            <Col span={13} style={{paddingLeft: '5px'}}>
              <GlobalSearchBar />
            </Col>
            <Col span={3}>
              <Dropdown overlay={info} trigger={['click']}>
                <div className="topmenubox">
                  <InfoCircleOutlined style={{ fontSize: '20px' }} />
                  <div style={{fontSize: '12px'}}>Info</div>
                </div>
              </Dropdown>
            </Col>
            <Col span={3}>
              <Dropdown overlay={user} trigger={['click']}>
                <div className="topmenubox">
                <UserOutlined style={{ fontSize: '20px' }} />
                  <div style={{fontSize: '12px'}}>Account</div>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </Layout.Header>
    </>
  )
})

export { Topbar }
