import { Menu, Badge, Layout} from 'antd'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { PATHS } from '../../constants'
import { authStore, stateStore } from '../../store'
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { GlobalSearchBar } from '../GlobalSearchBar'
import { Topbar } from '../Mobile'

import '../../styles/custom/search.css'
import '../../styles/custom/navbar.css'

function toggleMobileMenuOpen() {
  if (!stateStore.mobileMenuOpen) {
    if (isMobile) {
      stateStore.mobileMenuOpen = true
    }
  } else {
    stateStore.mobileMenuOpen = false
  }
}

const Navbar = observer((props) => {
  const authUser = authStore.isAuthenticated
  const mobileMenuOpen = stateStore.mobileMenuOpen
  const history = useHistory()
  const width = props.width

  function modalRouter(e) {
    console.log('click ', e['key'])
    if (e['key'] == 'signin') {
      history.push(PATHS.login)
    }
    if (e['key'] == 'logout') {
      authStore.logout()
    }
    if (e['key'] == 'aid') {
      stateStore.currentModal = 'RequestForm'
    }
    if (e['key'] == 'signup') {
      stateStore.currentModal = 'SignupCTA'
    }
    if (e['key'] == 'map') {
      stateStore.currentModal = 'MapLegend'
    }
    if (e['key'] == 'request') {
      stateStore.drawerVisible = !stateStore.drawerVisible
      console.log(stateStore.drawerVisible)
    }    if (e['key']=='ty') {
      stateStore.currentModal = 'ThankYou' 
    }
  }

  const renderLinks = () => {
    return [
      <Menu.SubMenu
        className="navbar"
        onClick={modalRouter}
        style={{
          float: 'right',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '180px',
        }}
        title={'Account'}
      >
        {authLinks}
        <Menu.Item key="map">
          <div className="dropdown">About the Map</div>
        </Menu.Item>
        <Menu.Item key="ty">
          <div className="dropdown">Acknowledgments</div>
        </Menu.Item>
        <Menu.Item key="helena">
          <div className="dropdown">Learn More</div>
        </Menu.Item>
      </Menu.SubMenu>,
    ]
  }

  const authLinks = !authStore.isAuthenticated ? (
    [
      <Menu.Item key="signup">Sign Up</Menu.Item>,
      <Menu.Item key="signin">Log In</Menu.Item>,
    ]
  ) : (
    <Menu.Item key="logout">
      <div className="dropdown">Log Out</div>
    </Menu.Item>
  )

  return (
    <>
      <Layout.Header
        className="navbar"
        style={{
          position: 'fixed',
          zIndex: 1,
          width: { width },
          height: '80px',
          // padding: isMobile ? '0' : '0 10%',
        }}
      >
        <Menu
          className="navbar-menu"
          theme="dark"
          mode="horizontal"
          style={{ lineHeight: '80px' }}
          // selectedKeys={'request'}
          onClick={modalRouter}
          // defaultOpenKeys = {['sub, sub2']}
        >
          <Menu.Item key={'request'}>
            <Badge
              count={stateStore.totalRequestsShown}
              overflowCount="10000"
              style={{ zIndex: '9999', backgroundColor: '#004799' }}
              offset={[0, -20]}
            >
              See Requests
            </Badge>
          </Menu.Item>

          <Menu.Item key={'aid'}>Submit Request</Menu.Item>

          <Menu.Item key={'search'} style={{ flex: '100%' }}>
            <GlobalSearchBar />
          </Menu.Item>
          {renderLinks()}
          {/* {authUser && renderAuthLinks()} */}
          {/* {!authUser && renderCTALinks()} */}
        </Menu>
      </Layout.Header>
    </>
  )
})
export { Navbar }
