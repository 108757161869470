import React, { useState } from 'react'
import { observer } from 'mobx-react'

import {RequestList} from './RequestList'

import { authStore, stateStore } from '../../store'
import {MapLayerSelector} from '../MapLayerSelector'
import { useQuery } from '@apollo/react-hooks'
import {isMobile} from 'react-device-detect';

const RequestDrawer = observer(() => {
  const showDrawer =
    stateStore.drawerVisible === null ? authStore.isAuthorized : stateStore.drawerVisible
  const drawerClass = `requestDrawer ${showDrawer ? 'open' : ''}`

  const menuVisible =  isMobile ? 'none': 'block'

  function handleRequestAid(e) {
    if (authStore.isAuthenticated) stateStore.currentModal = 'RequestForm'
    else stateStore.currentModal = 'SignupCTA'
  }


  let buttonText = 'Request or Provide Aid'
  if (authStore.isAuthenticated) {
    buttonText = 'Request Aid'
  }

  function setDrawerVisible (e) {
    stateStore.drawerVisible = e
  }

  return (
    <div className={drawerClass.concat(isMobile ? ' mobile-request' : '')}>
      <div className="drawerTop">
      <button
        className="newRequest"
        onClick={handleRequestAid}
        style={{ textTransform: 'uppercase'}}
        
      >
        <img src="img/white-circle-alert.svg" alt="" />
        {buttonText}
      </button >
      </div>

      <div className="drawerControls">
        
        <button onClick={() => setDrawerVisible(!showDrawer)}>
        <img className="closeIcon" src="img/blue-x.svg" alt="Close" />
        </button>
      </div>

      
        <RequestList />
    
      <div className="drawerBottom">
        <img src="img/helena.png" alt="Helena" />
        <span>COVID Network</span>
        <a
          href="https://helena.org/projects/covid-19-response"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          learn more
        </a>
      </div>
    </div>
  )
}) 

export {RequestDrawer}