import { Descriptions, Button, Col, List, Row, Select, Skeleton} from 'antd'
import React, { useState } from 'react'
import { authStore } from "../../store"
import { stateStore } from '../../store'
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import { GET_COUNTY } from '../../queries/RequestList.gql';


const RequestItem = (props) => {
  const request = props.request
  const requestId = request.request_id
  const responderFirstName = props.responderFirstName
  const responderLastName = props.responderLastNames
  const responderOrganization = props.responderOrganization

  const [isOpen, setIsOpen] = useState(false)

  function handleRespondClick(e) {
    stateStore.currentModal = 'SendResponseEmail'
    stateStore.selectedRequestInformation = {
      userId : authStore.userId,
      requestId : requestId,
      responderFirstName : responderFirstName,
      responderLastName : responderLastName,
      responderOrganization : responderOrganization,
      itemType : request.item
    }
  }

  function handleRespondClickUnauthenticated(e) {
    stateStore.currentModal = 'ProviderSignup'
  }

  function handleRespondClickUnauthorized(e) {
    stateStore.currentModal = 'ProviderAccountReview'
  }

  function closeRespondModal() {
    stateStore.currentModal = ''
  }

  function formatHospitalLocationString(hospital) {
    return hospital.hospital_name + ', ' + hospital.address.split(',').slice(1, 3)
  }
  //<Button title="Solve this" className="RequestList-solveThis" href={`mailto:mail+${authStore.userId}+${requestId}@covidnetwork.helena.org?subject=Response to Your Request #${requestId} for Item "${request.item}"`} > SOLVE THIS </Button>
  return (
    <List.Item key={requestId} >


      <div>
    
      <Row>
        <Col>
          <Descriptions size={'small'} column={1} style={{width:'260px'}}>
            <Descriptions.Item label={''}><h1>{request.item}{request.item_quantity ? ', ' : ''}{request.item_quantity}</h1></Descriptions.Item>
            <Descriptions.Item label="Type">{request.predicted ? "Predicted Need" : "Request"}</Descriptions.Item>
            <Descriptions.Item label="Location" >{authStore.isAuthorized ? formatHospitalLocationString(request.hospital) : request.county?.county}</Descriptions.Item>
            {request.organization_role && !request.predicted && (
                  <Descriptions.Item label="Requester role">{request.organization_role}</Descriptions.Item>
              ) 
            }
            {isOpen && request.county! && (
              <>
              <Descriptions.Item label="County cases/population">{request.county.cases} / {request.county.population}</Descriptions.Item>
              <Descriptions.Item label="Pred. cases in 1 week">{Math.round(request.county.predicted_cases)}</Descriptions.Item>
              </>
            )
            }
            {request.organization_role && request.hospital! && isOpen &&(
                <>
                  <Descriptions.Item label="Facility type">{request.hospital.type}</Descriptions.Item>
                </>
              )}
          </Descriptions>
        </Col>
      </Row>


      <Row>
        <Col offset={1}>
            <div style={{textAlign: 'center'}}>
          <Button
            className="RequestList-solveThis"
            onClick={() => setIsOpen(!isOpen)}
          >{isOpen ? "Less" : "More"}</Button>
            </div>
        </Col>
      </Row>
      </div>




       <div>
      { authStore.isAuthorized ? ([
      <Button title="Solve this" className="RequestList-solveThis" onClick={handleRespondClick}>SOLVE</Button>
    ]) : [
      authStore.isAuthenticated ? (
        <Button title="Solve this" className="RequestList-solveThis" onClick={handleRespondClickUnauthorized}>SOLVE</Button>
      ) : (
        <Button title="Solve this" className="RequestList-solveThis" onClick={handleRespondClickUnauthenticated}>SOLVE</Button>
      )
    ]}

    
 
   </div>
  
    </List.Item>
  )
}


export { RequestItem }