import gql from 'graphql-tag'




export const SampleRequestsCountyAuth = gql`
  query countySampleRequests($limit: Int!, $county_id: String!) {
    request(
      limit: $limit
      order_by: { timestamp: desc }
      where: { county_id: { _eq: $county_id }, predicted: {_eq: false} }
    ) {
      priority_score
      timestamp
      item
      county
      hospital_name
    }
  }
`

export const NationalSampleRequestsAuth = gql`
  query nationalRequestsSample($limit: Int!) {
    request(
      order_by: { timestamp: desc }
      limit: $limit
      where: { predicted: {_eq: false} } 
    ) {
      priority_score
      timestamp
      item
      county
      predicted
    }
  }
`

export const PriorityRequestsCountyAuth = gql`
  query SortedRequestsByCounty($limit: Int!, $county_id: String!) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { county_id: { _eq: $county_id } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      hospital_name
      predicted
      organization_role
    }
  }
`

export const PriorityRequestsHospitalAuth = gql`
  query SortedRequestsByHospital($limit: Int!, $hospital_name: String) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { hospital_name: { _eq: $hospital_name } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      hospital_name
      predicted
      organization_role
    }
  }
`


export const PriorityRequestsStateAuth = gql`
  query SortedRequests($limit: Int!, $state: String!) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { state: { _eq: $state } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      hospital_name
      predicted
      organization_role
    }
  }
`

export const SampleRequestsCounty = gql`
  query countySampleRequests($limit: Int!, $county_id: String!) {
    request(
      limit: $limit
      order_by: { timestamp: desc }
      where: { county_id: { _eq: $county_id }, predicted: {_eq: false} }
    ) {
      priority_score
      timestamp
      item
      county
    }
  }
`

export const NationalSampleRequests = gql`
  query nationalRequestsSample($limit: Int!) {
    request(
      order_by: { timestamp: desc }
      limit: $limit
      where: { predicted: {_eq: false} } 
    ) {
      priority_score
      timestamp
      item
      county
      predicted
    }
  }
`

export const PriorityRequestsCounty = gql`
  query SortedRequestsByCounty($limit: Int!, $county_id: String!) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { county_id: { _eq: $county_id } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      predicted
      organization_role
    }
  }
`

export const PriorityRequestsHospital = gql`
  query SortedRequestsByHospital($limit: Int!, $hospital_name: String) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { hospital_name: { _eq: $hospital_name } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      predicted
      organization_role
    }
  }
`


export const PriorityRequestsState = gql`
  query SortedRequests($limit: Int!, $state: String!) {
    request(
      limit: $limit
      order_by: { priority_score: desc }
      where: { state: { _eq: $state } }
    ) {
      priority_score
      timestamp
      item
      county
      request_id
      capacity
      cases
      predicted
      organization_role
    }
  }
`

/* 
Example query variables
{
  "limit": 1000,
  "items": ["N95 Masks", "Gloves", "Eye or face shield"],
  "city_search_string": "%CLEVELAND%|%ORLANDO%",
  "county_ids": ["36047"],
  "state_names": ["Nebraska", "Idaho"],
  "auth": true
}

*/

// This has some slight errors in how we manage searching multiple geographies
export const SEARCH_REQUESTS = gql`
query searchRequests($limit: Int, $items: [String!], $city_ids: [String!], $county_ids: [String!], $state_names: [String!], $facility_names: [String!], $auth: Boolean!) {
  request (
    limit: $limit
    order_by: [{predicted: asc}, {county: {predicted_cases: desc_nulls_last }}]
    where: {
      _and : [
        {item : {_in: $items}}
        {
          _or: [
            {hospital : {city : {city_id : {_in: $city_ids}}}}
            {hospital : {county : {county_id : {_in: $county_ids}}}}
            {hospital : {county : {state : {_in : $state_names}}}}
            {hospital : {hospital_name : {_in : $facility_names}}}
          ]
        }
      ]
    } 
    
  ){
		request_id
    item
    timestamp
    county {
      county
      county_id
      cases
      deaths
      predicted_cases
      population
    }
    item_quantity
    organization_role
    predicted
    item_id
    hospital @include(if: $auth) {
      hospital_name
      address
      type
      risk_score_pct
    }
  }
}
`
export const SEARCH_REQUESTS_COUNT = gql`
query searchRequestsCount($items: [String!], $city_ids: [String!], $county_ids: [String!], $state_names: [String!], $facility_names: [String!], $auth: Boolean!) {
  request_aggregate (
    order_by: [{predicted: asc}, { hospital: {risk_score: desc }}]
    where: {
      _and : [
        {item : {_in: $items}}
        {
          _or: [
            {hospital : {city : {city_id : {_in: $city_ids}}}}
            {hospital : {county : {county_id : {_in: $county_ids}}}}
            {hospital : {county : {state : {_in : $state_names}}}}
            {hospital : {hospital_name : {_in : $facility_names}}}
          ]
        }
      ]
    } 
    
  ){
		aggregate {
      count
    }
  }
}
`


export const GET_COUNTY = gql`
query getCounty {
  county {
    county
    county_id
  }
}
`
export const GET_ITEMS = gql`
query getItems {
  item {
    item_category
    item_name
    item_id
  }
}
`

export const GET_HOSPITAL = gql`
query getHospital {
  hospital ( order_by: {hospital_name: asc}) {
    hospital_name
    hospital_id
  }
}
`

export const GET_STATE = gql`
query getState {
  state{
    state
  }
}
`

export const GET_COUNTY_SEARCH = gql`
query getCounty  ($search_string: String, $search_string2: String, $limit: Int) {
  county  ( where: 
              {_or: [
                {county: {_ilike: $search_string}}, 
                {county: {_ilike: $search_string2}}
                ]
              }
            limit: $limit
          )
  {
    county
    county_id
    county_name
    state

  }
}
`
export const GET_HOSPITAL_SEARCH  = gql`
query getHospital ($search_string: String, $search_string2: String, $limit: Int){
  hospital ( where: 
              {_or: [
                {hospital_name: {_ilike: $search_string}}, 
                {hospital_name: {_ilike: $search_string2}}
                ]
              }, 
            order_by: {hospital_name: asc}
            limit: $limit
            ) 
  {
    hospital_name
    hospital_id
  }
}
`


export const GET_STATE_SEARCH  = gql`
query getState ($search_string: String, $search_string2: String, $limit: Int){
  state ( where: 
              {_or: [
                {state: {_ilike: $search_string}}, 
                {state: {_ilike: $search_string2}}
                ]
              }, 
            order_by: {state: asc}
          limit: $limit
            ) 
  {
    state
  }
}
`


export const GET_ITEM_SEARCH  = gql`
query getItems ($search_string: String, $search_string2: String, $limit: Int){
  item ( where: 
              {_or: [
                {item_name: {_ilike: $search_string}}, 
                {item_name: {_ilike: $search_string2}}
                ]
              }, 
            order_by: {item_name: asc}
            limit: $limit
            ) 
  {
    item_category
    item_name
    item_id
  }
}
`


export const GET_CITY_SEARCH = gql`
query getCity  ($search_string: String, $search_string2: String, $limit: Int) {
  city  ( where: 
              {_or: [
                {city_name: {_ilike: $search_string}}, 
                {city: {_ilike: $search_string2}}
                ]
              } 
              limit: $limit
          )
  {
    city
    city_name
    city_id
  }
}
`