import gql from 'graphql-tag'

const UserById = gql`
  query UserById($userId: String!) {
    users(where: {user_id: {_eq: $userId}}) {
      email
      phone
      first_name
      last_name
    }
  }
`
export {UserById}
