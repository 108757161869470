import React from 'react'
import { observer } from 'mobx-react'
import { Modal, Typography, notification, Row, Col, Button } from 'antd'
import { stateStore } from '../../store/index.js'

import '../../styles/Modal.css'
import Paragraph from 'antd/lib/skeleton/Paragraph'

const MapLegendModal = observer((props) => {
  const width = props.width || 540
  const onCancel = props.onCancel

  const visible = props.visible || stateStore.currentModal === 'MapLegend'
  const setVisible = (arg) => {
    if (arg) {
        stateStore.currentModal = 'MapLegend'
    } else {
        stateStore.currentModal = ''
    }
  }



  /*
  const note = {
    message: 'How You Can Help',
    key: 'popup',
    description:
      <Typography.Paragraph>We connect those who need resources to vetted aid providers and suppliers.
        <br></br><br></br>
        Are you a healthcare professional?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleReporterSignup}>Sign up as a Requester</a>{' '}.
        <br></br>
        Are you looking to assist in the efforts?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleResponderSignup}>Apply to be an Aid Provider</a>.   
          </Typography.Paragraph>,
    duration: 0,
    top: 200,
  }
  */


  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
    //notification.open(note)

  }

  return (
    <Modal
      centered
      visible={visible}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
      width={width}
      onCancel={handleCancel}
      title={
        <>

          <Typography.Title level={1}>Map Legend</Typography.Title>
          <Typography.Paragraph>__________</Typography.Paragraph>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Cases</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer reports the number of cases in each county as reported by Johns Hopkins.
          This layer refreshes every 24 hours.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Cases (1 Week)</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer a generated score for relative growth in cases in a county. This score is created
          from epidemiological models from CovidActNow, using variables including estimated contact ratio,
          social distancing policies and hospitalization rates.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Deaths</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer reports the number of cases in each county as reported by Johns Hopkins.
          This layer refreshes every 24 hours.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Deaths (1 week)</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer a generated score for relative growth in cases in a county. This score is created
          from epidemiological models from CovidActNow, using variables including estimated contact ratio,
          social distancing policies and hospitalization rates.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Population</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer represents the US Census Bureau's estimated population per county.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Poverty</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This is the poverty rate as calculated by the American Community Survey. Poverty rates often
          correspond to higher regional need for PPE and other supplies. 
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Comorbid conditions</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer represents the percentage of comorbid conditions, extracted from the HCC score
          from the Centers for Medicare and Medicaid. Higher rates of comorbid conditions correspond to higher
          hospitalization rates.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Social distancing</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          This layer represents the relative amount of social distancing in a region. This is calculated from the
          median number of hours spent at home, calculated from data provided by SafeGraph.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Medical facilities</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          Each point in this layer is a medical facility, and the size of each dot corresponds to the number of beds in each hospital.
          </Typography.Title>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Facility risk</Typography.Title>
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          Facility Risk is a calculated score for the relative case load of a medical facility based on the number of cases in its area.
          The larger the point, the more likely it is to be at, or past capacity.

          </Typography.Title>

          <Typography.Paragraph style={{textAlign: 'justify'}}><br></br>__________</Typography.Paragraph>
         
          
          <Row type="flex" gutter={2}>
            <Col span={24}>
              <Button style={{width:"50%"}} key="continue" type="primary" onClick={handleCancel}> Back </Button>
            </Col>
          </Row>

        </>
      }
      footer={null}
    >
      <div>
      </div>
    </Modal>
  )
})

export {MapLegendModal}