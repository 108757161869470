import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { stateStore } from '../../store'
import '../../styles/Modal.css'

const SignupConfirmModal = observer((props) => {
  const history = useHistory()
  const width = props.width || 540
  const onCancel = props.onCancel
  const visible = props.visible || stateStore.currentModal === 'SignupConfirm'
  const setVisible = (arg) => {
    if (arg) {
      stateStore.currentModal = 'SignupConfirm'
    } else {
      stateStore.currentModal = ''
    }
  }
  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  return (
    <Modal
    centered
    width={width}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
  title= { 
        <> <Typography.Title level={1}>Check your email for a confirmation link</Typography.Title> 
        <Typography.Paragraph>
          ___
        </Typography.Paragraph> 
      
          <Typography.Title level={4} style={{textAlign: 'justify'}}>
          <strong>Note for Aid Providers:</strong> Once you confirm your email, we have to review your account before we grant you access to respond to requests on the platform. We'll send you a second email when we're done with the review.
          </Typography.Title>
          <Typography.Paragraph>
          ___
          </Typography.Paragraph> </>
        }
      visible={visible}
      onCancel={() => stateStore.currentModal = ''}
      footer={null}
     > 
     
    </Modal>
)});

export {SignupConfirmModal}
