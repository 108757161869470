import React from 'react'
import { PointLayer, PolygonLayer, HeatmapLayer } from '@antv/l7-react'
import { stateStore } from '../../store'
import { observer } from 'mobx-react'

export const COLORS = [
  [
    '#9e1c00',
    '#ac3b15',
    '#b9542a',
    '#c66c3e',
    '#d18452',
    '#dc9b68',
    '#e6b27d',
    '#efc993',
    '#f7e1aa',
    '#fef9c1',
  ],
  [
    '#9e1c00',
    '#ae351e',
    '#bc4a37',
    '#ca5f4f',
    '#d57468',
    '#e08980',
    '#ea9e98',
    '#f2b3b0',
    '#f9c9c8',
    '#ffdfdf',
  ],
  [
    '#540538',
    '#7b065a',
    '#a30781',
    '#cb08ad',
    '#f408df',
    '#fa2bf5',
    '#f552fc',
    '#ef79fd',
    '#eea1fe',
    '#f2c9ff',
  ],
  [
    '#160044',
    '#240071',
    '#32009e',
    '#3f00cb',
    '#4b00f8',
    '#6727ff',
    '#8654ff',
    '#a581ff',
    '#c5aeff',
    '#e5dbff',
  ],
  [
    '#0f006c',
    '#03058d',
    '#0724ac',
    '#0c4ccb',
    '#137ce7',
    '#35aaea',
    '#59cee9',
    '#7be7eb',
    '#9cede3',
    '#bcf1e2',
  ],
  [
    '#08215e',
    '#0a3a7f',
    '#0a5ba1',
    '#0b85c4',
    '#0bb7e7',
    '#1ee2f7',
    '#3efaf5',
    '#5efce5',
    '#7ffedc',
    '#a1ffda',
  ],
  [
    '#005d98',
    '#0086b8',
    '#01b6d7',
    '#01eef7',
    '#1afeeb',
    '#3afdd7',
    '#5afdca',
    '#7afec5',
    '#99fec7',
    '#b9fed0',
  ],
  [
    '#006400',
    '#0a8600',
    '#1aa800',
    '#2ecb00',
    '#48ed00',
    '#6bff10',
    '#90ff32',
    '#b0ff55',
    '#caff77',
    '#dfff99',
  ],
  [
    '#7b5901',
    '#a07102',
    '#c58703',
    '#e99b05',
    '#f9a91a',
    '#f9b340',
    '#fabe65',
    '#fbcb8b',
    '#fcdaaf',
    '#fdead4',
  ],
  [
    '#54320e',
    '#754711',
    '#975f13',
    '#ba7814',
    '#de9415',
    '#efab28',
    '#f4be47',
    '#f9cf67',
    '#fcde88',
    '#feebaa',
  ],
]

export const CasesLayer = (props) => {
  const data = props.data
  const colors = COLORS[0].slice().reverse()
  return (
    <PolygonLayer
      key={'o'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'cases_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const CaseProjectionLayer = (props) => {
  const data = props.data
  const colors = COLORS[0].slice().reverse()
  return (
    <PolygonLayer
      key={'o'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      scale={{
        values: {
          cases_delta_pct: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'cases_delta_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const DeathsLayer = (props) => {
  const data = props.data
  const colors = COLORS[1].slice().reverse()
  return (
    <PolygonLayer
      key={'o'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'deaths_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const DeathProjectionLayer = (props) => {
  const data = props.data
  const colors = COLORS[1].slice().reverse()
  return (
    <PolygonLayer
      key={'o'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      scale={{
        values: {
          deaths_delta_pct: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'deaths_delta_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const PopulationLayer = (props) => {
  const data = props.data

  const colors = COLORS[5].slice().reverse()
  return (
    <PolygonLayer
      key={'2'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'population_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const PovertyLayer = (props) => {
  const data = props.data
  return (
    <PolygonLayer
      key={'2'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'poverty_pct',
        values: COLORS[3],
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const ComorbidityLayer = (props) => {
  const data = props.data
  const colors = COLORS[4].slice().reverse()

  return (
    <PolygonLayer
      key={'n'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'hcc_score',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const SocialDistancingLayer = (props) => {
  const data = props.data
  const colors = COLORS[5].slice().reverse()

  return (
    <PolygonLayer
      key={'n'}
      options={{
        zIndex: 0
      }}
      source={{
        data,
      }}
      color={{
        field: 'social_distance_score2_pct',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const HospitalBedsLayer = (props) => {
  const data = props.data
  const select = props.select
  const colors = COLORS[4].slice().reverse()
  const filter = props.filter
  // if there's no data to display, return no layer. 
  // This is hacky, but I found when you update the props of a map layer from having data, 
  //to an empty array of data, the map layer doesn't re-render. 
  // So instead, we return an empty jsx object
  if (data.length == 0) { 
    return (<></>)
  }
  return (
    <>
      <PointLayer
        options={{
          zIndex: 10,
          minZoom: 6
        }}
        filter={filter}
        source={{
          data,
          parser: {
            type: 'json',
            x: 'long',
            y: 'lat',
          },
        }}
        shape={{
          values: 'hexagon',
        }}
        color={{
          field: 'beds_pct',
          values: colors,
        }}
        size={{
          values: (h) => (h*4 + 10),
          field: 'beds_pct',
        }}
        active={{
          option: {
            color: '#CC3D00',
          },
        }}
        select={{ select }}
        style={{
          opacity: .6,
          strokeWidth: 0,
        }}
      >
        {props.children}
      </PointLayer>
      <PointLayer
        filter={filter}
        options={{
          zIndex: 10,
          maxZoom: 6
        }}
        source={{
          data,
          parser: {
            type: 'json',
            x: 'long',
            y: 'lat',
          },
        }}
        shape={{
          values: 'hexagon',
        }}
        color={{
          field: 'beds_pct',
          values: colors,
        }}
        size={{
          values: (h) => (h*1.3 + 2),
          field: 'beds_pct',
        }}
        active={{
          option: {
            color: '#CC3D00',
          },
        }}
        select={{ select }}
        style={{
          opacity: .6,
          strokeWidth: 0,
        }}
      >
        {props.children}
      </PointLayer>

    </>
  )
}

export const HospitalCapacityLayer = (props) => {
  const data = props.data
  const select = props.select
  const filter = props.filter
  const colors = COLORS[6].slice()
  // if there's no data to display, return no layer. 
  // This is hacky, but I found when you update the props of a map layer from having data, 
  //to an empty array of data, the map layer doesn't re-render. 
  // So instead, we return an empty jsx object
  if (data.length == 0) {
    return (<></>)
  }
  return (
    <>
    <PointLayer
      options={{
        zIndex: 0,
        minZoom: 6
      }}
      source={{
        data,
        parser: {
          type: 'json',
          x: 'long',
          y: 'lat',
        },
      }}
      shape={{
        values: 'hexagon',
      }}
      color={{
        field: 'risk_score_pct',
        values: colors,
      }}
      scale={{
        values: {
          risk_score: {
            type: 'quantile',
          },
        },
      }}
      size={{
        values: (h) => (Math.pow(h, 3)*5 + 10),
        field: 'risk_score_pct',
      }}
      active={{
        option: {
          color: '#CC3D00',
        },
      }}
      filter={filter}
      select={{ select }}
      style={{
        opacity: .6,
        strokeWidth: 0,
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PointLayer>
    <PointLayer
      filter={filter}
      options={{
        zIndex: 0,
        maxZoom: 6
      }}
      source={{
        data,
        parser: {
          type: 'json',
          x: 'long',
          y: 'lat',
        },
      }}
      shape={{
        values: 'hexagon',
      }}
      color={{
        field: 'risk_score_pct',
        values: colors,
      }}
      scale={{
        values: {
          risk_score: {
            type: 'quantile',
          },
        },
      }}
      size={{
        values: (h) => (Math.pow(h, 3)*5 + 10)/3,
        field: 'risk_score_pct',
      }}
      active={{
        option: {
          color: '#CC3D00',
        },
      }}
      select={{ select }}
      style={{
        opacity: .6,
        strokeWidth: 0,
      }}
      active={{
        option: {
          color: '#0c2c84',
        },
      }}
    >
      {props.children}
    </PointLayer>
    </>
  )
}




/*

export const FirstLayer = (props) => {
  const data = props.data
  const select = props.select
  return (
    <PointLayer
      source={{
        data,
        parser: {
          type: 'json',
          x: 'long',
          y: 'lat',
        },
      }}
      shape={{
        values: 'circle',
      }}
      color={{
        values: '#bea2e3',
      }}
      size={{
        values: (h) => (h + 10) / 60,
        field: 'beds',
      }}
      active={{
        option: {
          color: '#CC3D00',
        },
      }}
      select={{ select }}
      style={{
        opacity: .6,
      }}
    >
      {props.children}
    </PointLayer>
  )
}

export const hosLayer = (props) => {
  const data = props.data
  const select = props.select
  return (
    <PointLayer
      source={{
        data,
        parser: {
          type: 'json',
          x: 'long',
          y: 'lat',
        },
      }}
      color={{
        values: '#FF69B4',
      }}
      shape={{
        values: 'pentagon',
      }}
      active={{
        option: {
          color: '#CC3D00',
        },
      }}
      select={{ select }}
      size={{
        values: 10,
      }}
      style={{
        opacity: .6,
      }}
    >
      {props.children}
    </PointLayer>
  )
}

export const StaffLayer = (props) => {
  const data = props.data
  return (
    <PointLayer
      source={{
        data,
        parser: {
          type: 'json',
          x: 'long',
          y: 'lat',
        },
      }}
      color={{
        values: '#FF69B4',
      }}
      active={{
        option: {
          color: '#CC3D00',
        },
      }}
      size={{
        values: 10,
      }}
      style={{
        opacity: .6,
      }}
    >
      {props.children}
    </PointLayer>
  )
}
export const ThirdLayer = (props) => {
  const data = props.data
  return (
    <PolygonLayer
      key={'2'}
      source={{
        data,
      }}
      scale={{
        values: {
          deaths: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'population',
        values: [
          '#2E8AE6',
          '#69D1AB',
          '#DAF291',
          '#FFD591',
          '#FF7A45',
          '#CF1D49',
        ],
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: .6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}
export const InfectionLayer = (props) => {
  const data = props.data
  const colors = [
    '#141414',
    '#281b2e',
    '#3c2348',
    '#502b62',
    '#64327c',
    '#793a96',
    '#8d42b0',
    '#a149ca',
    '#b551e4',
    '#ca59ff',
  ]
  return (
    <PolygonLayer
      key={'2'}
      source={{
        data,
      }}
      scale={{
        values: {
          confirmed: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'confirmed',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: 0.4,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}
export const PovLayer = (props) => {
  const data = props.data
  const color = [
    '#f7f3f9',
    '#dbe6f1',
    '#c0d9ea',
    '#a5cce3',
    '#89bfdc',
    '#6eb2d5',
    '#53a5ce',
    '#3798c7',
    '#1c8bc0',
    '#017fb9',
  ]
  return (
    <PolygonLayer
      key={'2'}
      source={{
        data,
      }}
      scale={{
        values: {
          poverty: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'poverty',
        values: color,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: .6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}
export const OldLayer = (props) => {
  const data = props.data
  const colors = [
    '#f7f3f9',
    '#f6e8dd',
    '#f6dec1',
    '#f5d4a6',
    '#f5ca8a',
    '#f4c06f',
    '#f4b653',
    '#f3ac38',
    '#f3a21c',
    '#f39801',
  ].reverse()
  return (
    <PolygonLayer
      key={'n'}
      source={{
        data,
      }}
      scale={{
        values: {
          avg_age: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'avg_age',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: .6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}

export const predICULayer = (props) => {
  const data = props.data
  const colors = [
    '#f7f3f9',
    '#f6e8dd',
    '#f6dec1',
    '#f5d4a6',
    '#f5ca8a',
    '#f4c06f',
    '#f4b653',
    '#f3ac38',
    '#f3a21c',
    '#f39801',
  ].reverse()
  return (
    <PolygonLayer
      key={'n'}
      source={{
        data,
      }}
      scale={{
        values: {
          icubed_mean: {
            type: 'quantile',
          },
        },
      }}
      color={{
        field: 'icubed_mean',
        values: colors,
      }}
      shape={{
        values: 'fill',
      }}
      style={{
        opacity: .6,
      }}
      select={{
        option: { color: '#FFD591' },
      }}
    >
      {props.children}
    </PolygonLayer>
  )
}
*/

export const layerDict = {
  'Medical facilities': {layer: HospitalBedsLayer, authOnly: false, type: 'facility', defaultOn: true},
  'Facility risk': {layer: HospitalCapacityLayer, authOnly: true, type: 'facility', defaultOn: false},
  'Estimated Total Cases': {layer: CasesLayer, authOnly: false, type: 'county', defaultOn: true },
  'Projected Total Cases (1 week)': {layer: CaseProjectionLayer, authOnly: false, type: 'county', defaultOn: false},
  Deaths: {layer: DeathsLayer, authOnly: false, type: 'county'},
  'Death projection (1 week)': {layer: DeathProjectionLayer, authOnly: false, type: 'county', defaultOn: false},
  Population: {layer: PopulationLayer, authOnly: false, type: 'county', defaultOn: false},
  Poverty: {layer: PovertyLayer, authOnly: false, type: 'county', defaultOn: false},
  'Comorbid conditions': {layer: ComorbidityLayer, authOnly: false, type: 'county', defaultOn: false},
  'Social distancing': {layer: SocialDistancingLayer, authOnly: false, type: 'county', defaultOn: false},
}

