import React from 'react'
import { observer } from 'mobx-react'
import { Modal, Typography, notification, Row, Col, Button, Layout } from 'antd'
import { stateStore } from '../../store/index.js'

import '../../styles/Modal.css'
import Paragraph from 'antd/lib/skeleton/Paragraph'

const LandingModal = observer((props) => {
  const { Header, Footer, Sider, Content } = Layout
  const width = props.width || 900
  const footerWidth = props.width || 500
  const onCancel = props.onCancel

  const visible = props.visible || stateStore.currentModal === 'Landing'
  const setVisible = (arg) => {
    if (arg) {
        stateStore.currentModal = 'Landing'
    } else {
        stateStore.currentModal = ''
    }
  }

  function handleResponderSignup(e) {
    e.preventDefault()
    stateStore.currentModal = 'ProviderSignup'
    notification.close('popup')
  }

  function handleReporterSignup(e) {
    e.preventDefault()
    stateStore.currentModal = 'RequesterSignup'
    notification.close('popup')
  }


  /*
  const note = {
    message: 'How You Can Help',
    key: 'popup',
    description:
      <Typography.Paragraph>We connect those who need resources to vetted aid providers and suppliers.
        <br></br><br></br>
        Are you a healthcare professional?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleReporterSignup}>Sign up as a Requester</a>{' '}.
        <br></br>
        Are you looking to assist in the efforts?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleResponderSignup}>Apply to be an Aid Provider</a>.   
          </Typography.Paragraph>,
    duration: 0,
    top: 200,
  }
  */


  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
    //notification.open(note)

  }

  return (
  
    <Modal
      centered
      visible={visible}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
      width={width}
      onCancel={handleCancel}
      title={
        <>
        <Row justify="end">
          <Col flex="1 1 320px">
          <div style={{ marginTop: '0px', textAlign: 'left', marginRight:'40px'}}>
            <Typography.Title level={1} style={{textAlign: 'left'}}>
            Connecting those who need medical supplies 
              
              with those who can provide them.

            </Typography.Title>
            <Typography.Title level={3} style={{textAlign: 'left'}}>
            We've moved:<br></br>
            13,311,100 masks<br></br>
              7,154,000 gloves<br></br>
              5000 face shields<br></br>
              1000 ventilators<br></br>
              </Typography.Title>
             <br></br> 
            <Button key="Enter" type="primary" onClick={handleCancel}> Continue </Button>

            </div>
          </Col>

        <Col flex="0 1 500px">
        

          <div style={{textAlign: 'left'}}>
          {stateStore.currentModal == 'Landing' ? (<iframe src="https://player.vimeo.com/video/415774129" style={{width:'500px', height: '500px'}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>) : (<></>)}

          </div>
        </Col>

        </Row>
        <div style={{marginTop: '70px'}}>
          <Typography.Title level={4} style={{textAlign: 'center'}}>

          The Covid Network is a <a href="https://helena.org/">Helena </a>Project.

          </Typography.Title>
        </div>
          

        </>
      }
      

      footer={
        <div width={footerWidth}px>
        {/* <Typography.Paragraph style={{textAlign: 'center', fontFamily: 'EB Garamond'}}>

        We are a nonprofit project to help those on the front lines of the COVID-19 efforts.

          To become more involved or learn more, please email us at will@helena.org 
          </Typography.Paragraph> */}
          </div>

      }
    >

    </Modal>

  )
})

export {LandingModal}