import { LayerEvent, MapboxScene, Popup } from '@antv/l7-react'

import { Spin } from 'antd'
import * as React from 'react'
import { observer } from 'mobx-react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { stateStore, authStore } from '../../store'
import { GET_COUNTY_FIELDS, GET_COUNTY_GEO, GET_HOSPITAL, REQUESTS_AUTH, REQUESTS} from '../../queries/Map.gql'
import { SEARCH_REQUESTS } from '../../queries/RequestList.gql'

import { layerDict } from './MapLayers'
import { toJS } from 'mobx'
import '../../styles/Map.css'

const today = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  .toString()
  .substring(4, 10).toUpperCase

const LoadingComponent = () => {
  return (
    <div
    style={{
      position: 'relative',
      width: '100%',
      height: '100%',
    }}
  >
    <div style={{
      position: 'absolute',
      margin: '0',
      width: '100%',
      textAlign: 'center',
      top: '45%',
    }}>
      <Spin size="large" />{' '}
    </div>
  </div>
  )
}

const World = observer(function Map() {


  // Establish functions for showing popups
  function showCountyPopup(args) {
    setCountyPopupInfo({
      lnglat: args.lngLat,
      feature: args.feature,
      n_requests: args.n_requests,
      topRequests : args.topRequests,
      uniqueRequests : args.uniqueRequests
    })
  }

  function showAuthHospitalPopup(args) {
    setHospitalPopupInfo({
      lnglat: args.lngLat,
      feature: args.feature,
      risk_score: args.risk_score,
      n_requests: args.n_requests,
      topRequests : args.topRequests,
      cases: args.cases,
      capacity : args.capacity
    })
  }

  function showHospitalPopup(args) {
    setHospitalPopupInfo({
      lnglat: args.lngLat,
      feature: args.feature,
    })
  }

  function manageCountyClick(e) {

    const selectedCounty = e.feature.properties.GEO_ID.slice(
      -5
    )

      if (requests.data) {
      const referenceRequests = requests.data.request
      const selectedRequests = requests.data.request.filter(r => r.county_id === selectedCounty)


      const referenceRequestsCounts = {}
      for (let i = 0; i < referenceRequests.length; i++) {
        const item = referenceRequests[i].item
        referenceRequestsCounts[item] = referenceRequestsCounts[item] ? referenceRequestsCounts[item] + 1 : 1
      }

      const selectedRequestsCounts = {}
      for (let i = 0; i < selectedRequests.length; i++) {
        const item = selectedRequests[i].item
        selectedRequestsCounts[item] = selectedRequestsCounts[item] ? selectedRequestsCounts[item] + 1 : 1
      }

      let topRequests = []
      let uniqueRequests = []

      for (let k in selectedRequestsCounts) {
        topRequests.push([k, selectedRequestsCounts[k]])
      }

      for (let k in referenceRequestsCounts) {
        if (selectedRequestsCounts.hasOwnProperty(k)) {
          uniqueRequests.push([k, selectedRequestsCounts[k]/referenceRequestsCounts[k]])
        }
      }

      topRequests.sort((a, b) => b[1] - a[1])
      topRequests = topRequests.map((x) => x[0])

      uniqueRequests.sort((a, b) => b[1] - a[1])
      uniqueRequests = uniqueRequests.map((x) => x[0])

      e.topRequests = topRequests.slice(0,3)
      e.uniqueRequests = uniqueRequests.slice(0,3)
      e.n_requests = selectedRequests.length

      showCountyPopup(e)
    }

  }

  function manageAuthHospitalClick(e) {

    const selectedHospital = e.feature.hospital_name

    const selectedCounty = e.feature.county_id
    if (requests.data) {

    const referenceRequests = requests.data.request.filter(r => r.county_id === selectedCounty)
    const selectedRequests = requests.data.request.filter(r => r.hospital?.hospital_name === selectedHospital)

      const referenceRequestsCounts = {}
      for (let i = 0; i < referenceRequests.length; i++) {
        const item = referenceRequests[i].item
        referenceRequestsCounts[item] = referenceRequestsCounts[item] ? referenceRequestsCounts[item] + 1 : 1
      }

      const selectedRequestsCounts = {}
      for (let i = 0; i < selectedRequests.length; i++) {
        const item = selectedRequests[i].item
        const score = 4 - selectedRequests[i].priority_score
        selectedRequestsCounts[item] = selectedRequestsCounts[item] ? selectedRequestsCounts[item] + score : score
      }

      let topRequests = []
      let cases = '-'
      let capacity = '-'

      const caseCapacityRequests = selectedRequests.filter(d => !d.predicted)
      // Need to improve this math right here... will work for a week or so, but needs to eventually be a Bayesian time series model
      if (caseCapacityRequests.length > 0) {
        cases = Math.round(caseCapacityRequests.map((r) => r.cases).reduce((tot, v) => tot + v)/caseCapacityRequests.length)
        capacity = Math.round(caseCapacityRequests.map((r) => r.capacity).reduce((tot, v) => tot + v)/caseCapacityRequests.length)
      } 
      for (let k in selectedRequestsCounts) {
        topRequests.push([k, selectedRequestsCounts[k]])
      }


      topRequests.sort((a, b) => b[1] - a[1])
      topRequests = topRequests.map((x) => x[0])

      e.topRequests = topRequests.slice(0,3)
      e.n_requests = selectedRequests.length
      e.cases = cases
      e.capacity = capacity
      showAuthHospitalPopup(e)
    }
    

  }

  function manageHospitalClick(e) {


    showHospitalPopup(e)

  }

  function hospitalPopup() {
    return (
      <Popup lnglat={hospitalPopupInfo.lnglat} zIndex={3000}>
      <div className="hospitalTooltipContext">
        <div>
          <h2>
            {hospitalPopupInfo.feature.hospital_name}
          </h2>
        </div>
      </div>
      <div className="hospitalTooltipContent">
        <strong>{hospitalPopupInfo.feature.type}</strong>
        <table>
          <th>BEDS</th>
          <th>RISK SCORE</th>
          <tr>
            <td>{hospitalPopupInfo.feature.beds}</td>
            <td>{Math.round(hospitalPopupInfo.feature.risk_score)}</td>
          </tr>
        
        </table>
      </div>
    </Popup>
    )
  }

  function authHospitalPopup() {
    return (
    <Popup lnglat={hospitalPopupInfo.lnglat} zIndex={3000}>
        <div className="hospitalTooltipContext">
          <div>
            <h2>
              {hospitalPopupInfo.feature.hospital_name}
            </h2>
          </div>
          {/*
          <div className="distanceScore">
            RISK
            <div>
              {Math.round(100*hospitalPopupInfo.feature.risk_score)}
            </div>
            SCORE
          </div>
          */}
        </div>
        <div className="hospitalTooltipContent">
          <strong>{hospitalPopupInfo.feature.type}</strong>
          <table>
            <th>CASES</th>
            <th>RISK SCORE</th>
            <th>BEDS</th>
            <tr>
              <td>{hospitalPopupInfo.cases}</td>
              <td>{hospitalPopupInfo.risk_score}</td>
              <td>{hospitalPopupInfo.feature.beds}</td>
            </tr>
          
          </table>
        </div>
        <div className="tooltipForecast">
          <strong>GREATEST NEEDS</strong>
          <ul>
            <li>
              <span>{hospitalPopupInfo.topRequests[0]}</span>
            </li>
            <li>
              <span>{hospitalPopupInfo.topRequests[1]}</span>
            </li>
            <li>
              <span>{hospitalPopupInfo.topRequests[2]}</span>
            </li>
          </ul>
        </div>
      </Popup>
    )
  }

  function countyPopup() {
    return (
      <Popup lnglat={countyPopupInfo.lnglat} 
        option={{
          closeButton: true
        }}
      >

        <div className="tooltipContext">
          <div>
            <h2>
              {countyPopupInfo.feature.properties.county},{' '}
              {countyPopupInfo.feature.properties.state}
            </h2>
          </div>
          {/*
          <div className="distanceScore">
            AID
            <div>
              {popupInfo.n_requests}
            </div>
            REQUESTS
          </div>
          */}
        </div>
        <div className="tooltipContent">
        <div className="tooltipForecast">
          <strong>ESTIMATED TOTAL CASES</strong>
          </div>
          <table>
            <tr>
            <th></th>
              <th>TODAY</th>
              <th>1 WEEK FORECAST</th>
            </tr>
            <tr>
              <td><strong>Cases</strong></td>
              <td>{Math.round(countyPopupInfo.feature.properties.cases)}</td>
              <td>{Math.round(countyPopupInfo.feature.properties.predicted_cases)}</td>
            </tr>
            <tr>
              <td><strong>Deaths</strong></td>
              <td>{Math.round(countyPopupInfo.feature.properties.deaths)}</td>
              <td>{Math.round(countyPopupInfo.feature.properties.predicted_total_deaths)}</td>
            </tr>
          </table>
        </div>
      </Popup>
    )
  }

  // Establish state of the map for county and hospital
  const [countyPopupInfo, setCountyPopupInfo] = React.useState()
  const [hospitalPopupInfo, setHospitalPopupInfo] = React.useState()
  const [filters, setFilters] = React.useState({shouldFilter: false, filteredCounties: {}, filteredHospital: {}})


  // Establish what layers should be shown
  const authLayers = Object.keys(layerDict).filter((key) => {return layerDict[key].authOnly})
  const publicLayers = Object.keys(layerDict).filter((key) => {return !layerDict[key].authOnly})

  const mapLayers = authStore.isAuthorized
  ? authLayers.concat(publicLayers)
  : publicLayers

  // Pull down data
  const hospitalResponse = useQuery(GET_HOSPITAL)
  const countyFeaturesResponse = useQuery(GET_COUNTY_FIELDS)
  var countyResponse = useQuery(GET_COUNTY_GEO)
  const requests = useQuery(REQUESTS, {variables: {limit: 1000, auth: toJS(authStore.isAuthorized)}})

  const items = stateStore.selectedItemFilters
  const counties = stateStore.selectedCounties
  const states = stateStore.selectedStates
  const cities = stateStore.selectedCities
  const facilities = stateStore.selectedFacilities


  //TODO: refactor the below to make this easier to follow 
  let searchVariables = {variables : {}} // create a dictionary of variables to pass to the SEARCH_REQUEST query
  if (items.length > 0) { 
    searchVariables.variables['items'] = items // only include items if we have them, otherwise leave them out so the query doesn't search on this field
  }

  if (counties.length > 0 || states.length > 0 || cities.length > 0 || facilities.length > 0) { //Search on geography constraints if any 
    searchVariables.variables['county_ids'] = counties
    searchVariables.variables['state_names'] = states
    searchVariables.variables['city_ids'] = cities
  }

  if (authStore.isAuthorized && facilities.length > 0) { // If authorized, search on facility level
    searchVariables.variables['facility_names'] = facilities
  } else {
    searchVariables.variables['facility_names'] = []
  }

  searchVariables.variables['auth'] = toJS(authStore.isAuthorized)

  searchVariables.variables['limit'] = 1000

  searchVariables['onCompleted'] = ((d) => { // onComplete fires ONCE when the query is complete, enabling us to setState without infinite re-render loop

    const shouldFilter = ((items.length + facilities.length + cities.length + counties.length + states.length) > 0) // only filter the map if you're searching for something
    if (d.request.length > 0) { // if there are > 0 requests returned by the search
      const filteredCounties = d.request.reduce((result, elt) => { // create a dictionary of the counties where there are requests
        if (elt.county) {
          result[elt.county.county_id] = true
        }
        return result
      }, {})

      const filteredHospitals = d.request.reduce((result, elt) => { // create a dictionary of the hospitals where there are requests 
        if (elt.hospital) {
          result[elt.hospital.hospital_name] = true
        }
        return result
      }, {})
      setFilters({shouldFilter: shouldFilter, filteredCounties: filteredCounties, filteredHospitals: filteredHospitals}) // set state of what map filters should be used
    } else {
      setFilters({shouldFilter: shouldFilter, filteredCounties: {}, filteredHospitals: {}}) // set empty map filters if there's no data
    }


  })

  const requestsQuery = useQuery(SEARCH_REQUESTS, searchVariables)




  // Pull down requests. Make both auth and unauth requests simultaneously so
  // we avoid the conditional react hook error.... I'm sure there's a better way to do this


  const loading =
  countyResponse.loading ||
  countyFeaturesResponse.loading ||
  hospitalResponse.loading ||
  requests.loading
  
  const error =
  countyResponse.error ||
  countyFeaturesResponse.error ||
  hospitalResponse.error ||
  requests.error


  //Manage error and loading states 
  if (loading)
    return (<LoadingComponent />)
  if (error) return <p> ERROR </p>
  // //console.log(error)
  //if (!hospitalResponse.data) return <p>Not found</p>


  // Unpack data from completed requests
  const hospitalData = hospitalResponse.data.hospital
  const countyData = countyResponse.data.county_geo['0']
  const countyFeatures = countyFeaturesResponse.data.county


  if (requestsQuery.error) {
    console.load(requestsQuery.error)
  } 
  // merge in the feature table data to the cursed geoJSON
  const fipsToFeatureIndex = {}
  const rowIndices = Array.from(Array(countyData.features.length).keys())
  rowIndices.map(
    (x) =>
      (fipsToFeatureIndex[
        countyData.features[x].properties.STATE +
          countyData.features[x].properties.COUNTY
      ] = x)
  )
  const featureIndices = Array.from(Array(countyFeatures.length).keys())

  featureIndices.forEach((x) => {
    const features = countyFeatures[x]
    if (features.hasOwnProperty('county_id')) {
     if (fipsToFeatureIndex[features.county_id]) {
        for (let k in features) {
          countyData.features[
            fipsToFeatureIndex[features.county_id]
          ].properties[k] = features[k]
        }

      }
    }
  })


 
  //pretty sure this is redundant info
  /* 
  countyData &&
    stateStore.visibleLayers
      .filter((l) => {return(layerDict[l].type === 'county')})
      .map((l) =>
        layerDict[l].layer({
          children: (
            <LayerEvent
              type="click"
              handler={manageCountyClick}
            />
          ),
          data: countyData,
          select: (x) => {
            // //console.log(x)
          },
        })
      )
  */

  return (
    <MapboxScene
      map={{
        center: [-100, 40.16797],
        pitch: 0,
        style: 'mapbox://styles/rochelleshen/ck8zt5a7f0pqq1in263hf1v5z',
        zoom: 4,
      }}
      option={{ logoVisible: false }}
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%'
      }}
    >
      {countyData &&
        Object.keys(stateStore.visibleMapLayers)
          .filter((l) => {return((layerDict[l].type === 'county') && (stateStore.visibleMapLayers[l]))})
          .map((l) =>
            layerDict[l].layer({
              children: (
                <LayerEvent
                  type="click"
                  handler={manageCountyClick}
                />
              ),
              data: countyData,
              select: (x) => {},
            })
          )},
      {hospitalData &&
        authStore.isAuthorized &&
        Object.keys(stateStore.visibleMapLayers)
          .filter((l) => {return((layerDict[l].type === 'facility') && (stateStore.visibleMapLayers[l]))})
          .map((l) =>
              layerDict[l].layer({
              children: [
                <LayerEvent
                  type="click"
                  handler={manageAuthHospitalClick}
                />
              ],
              data: hospitalData.filter((d) => {return(!filters.shouldFilter || filters.filteredHospitals.hasOwnProperty(d.hospital_name))}),
              select: (x) => {
              },
            })
          )}
      }
      {hospitalData &&
        !authStore.isAuthorized &&
        Object.keys(stateStore.visibleMapLayers)
          .filter((l) => {return((layerDict[l].type === 'facility') && (stateStore.visibleMapLayers[l]))})
          .map((l) =>
              layerDict[l].layer({
              children: [
                <LayerEvent
                  type="click"
                  handler={manageHospitalClick}
                />,
                <LayerEvent
                  type="mousemove"
                  handler={() => {}}
                />,
              ],
              data: hospitalData.filter((d) => {return(!filters.shouldFilter || filters.filteredCounties.hasOwnProperty(d.county_id))}),
              select: (x) => {
              }
            })
          )},
      {hospitalPopupInfo && 
        authStore.isAuthorized &&
          authHospitalPopup()
      },
      {hospitalPopupInfo && 
        !authStore.isAuthorized &&
          hospitalPopup()
      },
      {countyPopupInfo && 
        countyPopup()
      }
      }
    </MapboxScene>
  )
})
//ReactDOM.render(<World />, document.getElementById('map'));
export {World}
