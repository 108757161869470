import React from 'react'
import { Button, List, Spin } from 'antd'

export const NoRequestsAvailable = (props) => {
  return (
    <div className="noRequestsAvailable">
      <img src="img/no-items.svg" />
      <h2>No requests at this location</h2>
    </div>
  )
}
