import React from 'react'
import { observer } from 'mobx-react'
import { stateStore, authStore } from '../../store'

const LayoutSignupLink = observer(() => (
  <li>
    <button
      onClick={() => {
        stateStore.currentModal = 'SignupCTA' 
      }}
    >
      <img src="img/red-heart.svg" alt="Heart" /> SIGN UP
    </button>
  </li>
))

export {LayoutSignupLink}