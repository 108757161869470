import React, { useState } from 'react'
import { observer } from 'mobx-react'
import {
  AutoComplete,
  Checkbox,
  Col,
  Form,
  Input,
  Button,
  Radio,
  Row,
  Modal,
  Typography,
  Spin,
} from 'antd'

import { authStore, stateStore } from '../../store/index.js'

import '../../styles/Modal.css'
import '../../styles/ModalForm.css'

const radioStyle = { fontWeight: 700, textTransform: 'uppercase' }

function checkPassword(str) {
  // at least one number, one lowercase and one uppercase letter
  // at least six characters
  var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(str);
}

const ProviderSignupForm = observer((props) => {
  const gutter = props.gutter || 24
  const [signupInProgress, setSignupInProgress] = useState(false)

  const handleSignup = async (formData) => {
    console.log(formData)
    setSignupInProgress(true)
    await authStore.signup(formData)
    setSignupInProgress(false)
    if (!authStore.errorMsg) {
      stateStore.currentModal = 'SignupConfirm'
    }
    else {
      return false
    }
  }
  const hasError = (!authStore.inProgress && authStore.errorMsg)
  const emailError = (!authStore.inProgress && authStore.errorMsg && authStore.errorMsg.includes('email')) ? authStore.errorMsg : null
  const passwordError = (!authStore.inProgress && authStore.errorMsg && authStore.errorMsg.includes('Password')) ? authStore.errorMsg : null
  return (
    <Form
      name="responder_signup"
      className="ModalForm"
      layout="vertical"
      colon={false}
      onFinish={handleSignup}
    >
      <fieldset>
        <div className="Modal-zebra">
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Preferred email address"
                hasFeedback={emailError}
                validateStatus={emailError ? "error" : ''}
                help={emailError}

                extra={
                  <span style={{ opacity: 0.5 }}>We respect your privacy</span>
                }
                whitespace={true}
                rules={[
                  { required: true, message: 'Please enter a valid email.' },
                  { type: 'email', message: 'Please enter a valid email.' }
                ]}
              >
                <Input type="email" placeholder="address@domain.com" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="Modal-zebra">
          <Row gutter={gutter}>
            <Col span={24} md={24}>
              <Form.Item
                name="password"
                label="Enter a password"
                whitespace={true}
                hasFeedback={passwordError}
                validateStatus={passwordError ? "error" : ''}
                help={passwordError}
                rules={[{ required: true, message: 'Please enter a valid password.' }, { validator: (_, v) => checkPassword(v) ? Promise.resolve() : Promise.reject('Password must contain at least one number, one lowercase character, one uppercase character, and be at least eight characters long.') }]} >
                <Input.Password
                  placeholder="●●●●●●●●●●●"
                  visibilityToggle={true}
                  min={10}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="Modal-zebra">
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            What's your name?
          </Typography.Paragraph>
          <Row gutter={2} type='flex'>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label="First"
                rules={[{ required: true, message: 'First name is required.' }, { validator: (_, value) => value.length < 64 ? Promise.resolve() : Promise.reject('Field is limited to 64 characters.') },]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label="Last"
                rules={[{ required: true, message: 'Last name is required.' }, { validator: (_, value) => value.length < 64 ? Promise.resolve() : Promise.reject('Field is limited to 64 characters.') },]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="Modal-zebra">
          <Row>
            <Col span={24}>
              <Form.Item
                name="organization"
                label="If applicable, what organization do you represent?"
                rules={[{ validator: (_, value) => value == undefined || value.length < 128 ? Promise.resolve() : Promise.reject('Field is limited to 128 characters.') },]}
              >
                <Input placeholder="e.g. 3M, US Air Force, Red Cross" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="Modal-zebra">
          <Row>
            <Col span={24}>
              <Form.Item
                name="organization_role"
                label="If applicable, what's your role/title at that organization?"
                rules={[{ validator: (_, value) => value == undefined || value.length < 64 ? Promise.resolve() : Promise.reject('Field is limited to 64 characters.') },]}
              >
                <Input placeholder="e.g. CEO" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="Modal-zebra">
          <Row>
            <Col span={24}>
              <Form.Item
                name="resume"
                label="If you have a LinkedIn or other online profile, please link to it here"
                rules={[{ validator: (_, value) => value == undefined || value.length < 128 ? Promise.resolve() : Promise.reject('Field is limited to 128 characters.') },]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="Modal-zebra">
          <Row>
            <Col span={24}>
              <Form.Item
                name="explanation"
                rules={[{ required: true, message: 'Explanation is required.' }, { validator: (_, value) => value.length < 256 ? Promise.resolve() : Promise.reject('Field is limited to 256 characters.') },]}
                label="Briefly explain why you'd like to use The COVID Network"
                extra={
                  <span style={{ opacity: 0.5 }}>Max 256 characters</span>
                }

              >
                <Input.TextArea maxLength={256} placeholder="e.g. We have 300,000 masks we'd like to sell at cost" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </fieldset>
      {/* delete below */}
      <div
        className="ModalForm-footer"
        style={{ marginTop: gutter, paddingBottom: gutter }}
      >
        <Form.Item
          name="accept_agreement"
          label={
            <span>
              I agree to the <a href="/terms_privacy">Terms of Use and Privacy Policy</a>
            </span>
          }
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox />
        </Form.Item>


        <Spin spinning={signupInProgress} tip={"Submitting Request..."}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Account
          </Button>
          </Form.Item>
        </Spin>
      </div>
    </Form>
  )
})


const ProviderSignupModal = observer((props) => {
  const width = props.width || 540
  const onFinish = props.onFinish
  const onCancel = props.onCancel

  const visible = props.visible || stateStore.currentModal === 'ProviderSignup'
  const setVisible = (arg) => {
    if (arg) {
      stateStore.currentModal = 'ProviderSignup'
    } else {
      stateStore.currentModal = ''
    }
  }

  function handleFinish(formData) {
    typeof onFinish === 'function' && onFinish(formData)
    setVisible(false)
  }

  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
  }

  return (
    <Modal
      centered
      visible={visible}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
      width={width}
      onCancel={handleCancel}
      title={
        <>
          <div className="formHeader">
            {/* <div className="helenaBrand" style={{ textTransform: 'uppercase' }}> 
              <img src="img/helena.png" alt="Helena" />
              <span>COVID Network</span>
            </div>*/}
          </div>

          <Typography.Title level={1}>
            Help Better
          </Typography.Title>
          <Typography.Paragraph>___</Typography.Paragraph>
          <Typography.Title level={4}>
            We connect suppliers and donors of medical products with the healthcare institutions that need them.
          </Typography.Title>
          <Typography.Title level={4}>
            Join now to get connected with the institutions that most need your help, and to discover where areas of the greatest need are.
          </Typography.Title>
          <Typography.Paragraph>___</Typography.Paragraph>
        </>
      }
      footer={null}
    >
      <ProviderSignupForm onFinish={handleFinish} />
    </Modal>
  )
})

export { ProviderSignupModal }