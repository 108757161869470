import React from 'react'
import { observer } from 'mobx-react'
import { Modal, Typography, notification, Row, Col, Button } from 'antd'
import { stateStore } from '../../store/index.js'

import '../../styles/Modal.css'
import Paragraph from 'antd/lib/skeleton/Paragraph'

const ThankYouModal = observer((props) => {
  const width = props.width || 540
  const onCancel = props.onCancel

  const visible = props.visible || stateStore.currentModal === 'ThankYou'
  const setVisible = (arg) => {
    if (arg) {
        stateStore.currentModal = 'ThankYou'
    } else {
        stateStore.currentModal = ''
    }
  }



  /*
  const note = {
    message: 'How You Can Help',
    key: 'popup',
    description:
      <Typography.Paragraph>We connect those who need resources to vetted aid providers and suppliers.
        <br></br><br></br>
        Are you a healthcare professional?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleReporterSignup}>Sign up as a Requester</a>{' '}.
        <br></br>
        Are you looking to assist in the efforts?<br></br>
        {' '}
          <a href="#" role="button" onClick={handleResponderSignup}>Apply to be an Aid Provider</a>.   
          </Typography.Paragraph>,
    duration: 0,
    top: 200,
  }
  */


  function handleCancel(e) {
    typeof onCancel === 'function' && props.onCancel()
    setVisible(false)
    //notification.open(note)

  }

  const aid_organizations = [
      ['Grace Farms Foundation', 'https://gracefarms.org/grace-farms-foundation/'  ],
      ['Panda Cares', 'https://www.pandacares.org/'],
      ['Public Spend Forum', 'https://www.publicspendforum.net/'], 
      ['United States Air Force', 'https://www.airforce.com/']
  ]

  const data_partners = [
    ['American Hospital Association', 'https://www.aha.org/'  ],
    ['National Medical Capabilities (hospital demand data)', 'https://hospitaloutreachanddata.bubbleapps.io/'],
    ['SafeGraph (social distancing data)', 'https://www.safegraph.com/'], 
   ]

   const special_thanks = [
    ['Aaron Brodeur', 'https://github.com/brodeur'],
    ['Albert Rodenius', 'https://github.com/Californian'],
    ['Alex Strachan', 'https://github.com/metalsheep'],
    ['Ari Falkner', 'https://github.com/arithehun'],
    ['Prof. Blan Godfrey', 'https://textiles.ncsu.edu/directory/people/abgodfre'],
    ['Cam Cope', 'https://github.com/ccope'],
    ['Christos Koutsoyannis', 'https://www.linkedin.com/in/koutsoyannis/'],
    ['Decisive Point', 'http://decisivepoint.co/'  ],
    ['Jay Long', 'https://www.linkedin.com/in/jayllong/'],
    ['Kate Jones', 'https://www.linkedin.com/in/kate-jones-3461665/'],
    ['Nina Hwang', 'https://github.com/nihwang'],
    ['Philihp Busby', 'https://github.com/philihp'], 
    ['Prof. Robert Handfield', 'https://scm.ncsu.edu/people/rbhandfi'], 
    ['toastal', 'https://github.com/toastal'],
    ]



  return (
    <Modal
      centered
      visible={visible}
      maskClosable={true}
      className="Modal Modal--opaque"
      wrapClassName="Modal-wrap Modal-wrap--opaque"
      width={width}
      onCancel={handleCancel}
      title={
        <>

          <Typography.Title level={1}>Collaborators</Typography.Title>
          <Typography.Paragraph>__________</Typography.Paragraph>
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Aid Organizations</Typography.Title>
            {aid_organizations.map((elt) => (
                <>
                    <Typography.Title level={4} style={{textAlign: 'justify'}}>
                        <a href={elt[1]}>{elt[0]}</a>
                    </Typography.Title>
                </>
            ))}
          <Typography.Title level={2} style={{textAlign: 'justify'}}>Data Partners</Typography.Title>
          {data_partners.map((elt) => (
                <>
                    <Typography.Title level={4} style={{textAlign: 'justify'}}>
                        <a href={elt[1]}>{elt[0]}</a>
                    </Typography.Title>
                </>
            ))}
          <Typography.Title level={2} style={{textAlign: 'justify'}}>With special thanks to</Typography.Title>
          {special_thanks.map((elt) => (
                <>
                    <Typography.Title level={4} style={{textAlign: 'justify'}}>
                        <a href={elt[1]}>{elt[0]}</a>
                    </Typography.Title>
                </>
            ))}

          <Typography.Paragraph style={{textAlign: 'justify'}}><br></br>__________</Typography.Paragraph>
         
          
          <Row gutter={2}>
            <Col span={24}>
              <Button style={{width:"50%"}} key="continue" type="primary" onClick={handleCancel}> Back </Button>
            </Col>
          </Row>

        </>
      }
      footer={null}
    >
      <div>
      </div>
    </Modal>
  )
})

export {ThankYouModal}