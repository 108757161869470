import React from 'react'
import '../styles/App.css'
import { Login } from './Login'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Layout }from './Layout'
import { PATHS } from '../constants'
import { TermsAndPrivacy } from './TermsAndPrivacy'

function App() {
  return (
    <>
      <Router>
        <Route path={PATHS.login} component={Login} />
        <Route path={PATHS.dashboard} component={Layout} />
        <Route exact path={'/'} component={Layout} />
        <Route path={'/terms_privacy'} component={TermsAndPrivacy}/>
      </Router>
    </>
  )
}

export {App}
