import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { Layout as AntLayout } from 'antd'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { authStore, stateStore } from '../../store'

import {World, layerDict} from '../Map'
import { MapLayerSelector } from '../MapLayerSelector'
import { RequestDrawer } from '../RequestDrawer'
import {ProviderSignupModal, RequestCompleteModal, RequesterSignupModal, RequestFormModal, SendResponseEmailModal, SignupConfirmModal, SignupCTAModal, LandingModal, ProviderAccountReviewModal, MapLegendModal, ThankYouModal} from '../Modals'
import { GlobalSearchBar } from '../GlobalSearchBar'

import { LayoutSignInOutLink } from './LayoutSignInOutLink'
import { LayoutOfferAidLink } from './LayoutOfferAidLink'
import { LayoutRequestAidLink } from './LayoutRequestAidLink'

import { isMobile } from 'react-device-detect'

import '../../styles/custom/minireset.css'
import '../../styles/custom/globalStyles.css'
import '../../styles/custom/requestDrawer.css'
import '../../styles/custom/layerControls.css'
import '../../styles/custom/requestList.css'
import '../../styles/custom/typography.css'
import '../../styles/custom/menu.css'
import { auth } from '../firebase'
import { LayoutSignupLink } from './LayoutSignupLink'
import { Navbar } from '../Navigation'
import {Topbar} from '../Mobile'
import { LayoutMapLegendLink } from './LayoutMapLegendLink'
import { LayoutThankYouLink } from './LayoutThankYouLink'
import { Button } from 'antd'

// When people view the map in landscape mode, show this notification
const LandscapeNotification = () => {
  return (
    <div className="landscapeNotification">
      <div className="notification">
        <img className="notificationLogo" src="img/helena-logo-blue-150w.png" />
        <span style={{ textTransform: 'uppercase' }}>COVID Network</span>
        <div>
          <img src="img/landscape.svg" />
        </div>
        <p>The Covid Network map is optimized for viewing in portrait mode.</p>
        <p>
          <strong>
            To see the map, rotate your phone so that it is oriented vertically.
          </strong>
        </p>
      </div>
    </div>
  )
}



const MapMenu = observer(() => {
  return (
    <div className="menu">

      <div className="userContext">
        <div className="userMenu">
          <div className="userIcon">
            <img src="img/hamburger-menu.png" alt="Profile" />
          </div>
          <ul className="loggedOut">
            <div className="indicator"></div>
            <LayoutSignInOutLink />
            {authStore.isAuthenticated ? (<LayoutRequestAidLink />) : (<LayoutSignupLink/>)}
            <LayoutMapLegendLink />
            <LayoutThankYouLink />
          </ul>
        </div>
      </div>
    </div>
  )
})



class Layout extends React.Component {
  state = {
    collapsed: false,
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }


  render() {
    return (<>
      <AntLayout style={{
          height: window.innerHeight,
          display: "flex",
          flexDirection: "column"}}>
      {isMobile ? <Topbar></Topbar>
      : <Navbar/>
      }
      
      <AntLayout.Content className="mapContainer"> 

        {/* <GlobalSearchBar></GlobalSearchBar> */}
        <LandscapeNotification></LandscapeNotification>
        {/* <MapMenu></MapMenu> */}
        <RequestDrawer></RequestDrawer>
        <World className="map"></World>
        <div className="layerControls">
        <MapLayerSelector></MapLayerSelector>
      </div>
        <LandingModal></LandingModal>
        <ProviderSignupModal></ProviderSignupModal>
        <RequestCompleteModal></RequestCompleteModal>
        <RequesterSignupModal></RequesterSignupModal>
        <RequestFormModal></RequestFormModal>
        <SendResponseEmailModal></SendResponseEmailModal>
        <SignupConfirmModal></SignupConfirmModal> 
        <SignupCTAModal></SignupCTAModal>
        <ProviderAccountReviewModal></ProviderAccountReviewModal>
        <MapLegendModal></MapLegendModal>
        <ThankYouModal></ThankYouModal>
        {!isMobile ? (
          <div className='helenaLogo'>
            <a href="https://www.helena.org">
            < img src="/img/Helena-Monogram-Blue..png"  />
            </a>
          </div>
         ) : (
           <>
           <Button style={{
             position: 'absolute',
             bottom: '10px',
             right: '10px',
             minHeight: '36px',
             borderRadius: '36px', 
             backgroundColor: 'rgba(255, 255, 255, 1)',
            //  boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.2)',
             fontSize: '14px',
             fontFamily: "'Source Code Pro', monospace",
             fontWeight: '800',
             padding: '2px 20px',
             color: '#004799'

             
            }}
            onClick={() => {authStore.isAuthenticated ? stateStore.currentModal ='RequestForm'
              : stateStore.currentModal='SignupCTA'}}
            >REQUEST AID</Button>
             </>
         )}

        
        
      </AntLayout.Content>
      {/* <FooterMenu/> */}
      </AntLayout>
      </>
    )
  }
}

export {Layout}
