import React,{useState}from 'react'
import { Checkbox, Badge, Button} from 'antd'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { authStore, stateStore } from '../../store'
import { layerDict } from '../Map'

const MapLayerSelector = (() => {

  const colors = ['#d18452', '#c66c3e', '#b9542a', '#ac3b15', '#0bb7e7', '#6727ff', '#137ce7', '#1ee2f7',]
  const totalLayers = Object.keys(layerDict)
  
  const selectedValues = toJS(stateStore.visibleMapLayers)
  const [layerSelection, setLayerSelection] = useState(selectedValues)


  const LayerSwitches = observer(() => {
    const facilityLayers = totalLayers.filter((key) => {return layerDict[key].type === 'facility'})
    const countyLayers = totalLayers.filter((key) => {return layerDict[key].type === 'county'})

    function color(idx, value) { return selectedValues[value] ? "#213783" : colors[idx] }
    function status(idx, value) { return selectedValues[value] ? true: false}
    function weight(idx, value) { return selectedValues[value] ? {fontColor: 'blue'}: {fontWeight: 'normal'}}
    // function statusStyle(idx, value) { return selectedValues[value] ?  : "default"}

    const layerSet = (e, overlayName, type) => {

      if (layerSelection[overlayName]){
        layerSelection[overlayName] = false
      } else {
        type.forEach(v => layerSelection[v] = false)
        layerSelection[overlayName] = true
      }
      setLayerSelection(layerSelection)
      stateStore.visibleMapLayers = layerSelection
    }
    return (
      <li>
        <div className="countyLayers">
          <img src="img/blue-globe.svg" alt="" />
          <img src="img/white-globe.svg" alt="" />
          <div className="label header">
            <span>Map Layers</span>
          </div>
        </div>
        <ul >
        <div className="label">
            <span>COUNTIES</span>
        </div>
          <div className="countyChecks">
          {countyLayers.map((value, idx) => (
              <li key={idx}>
                <div className="label" style={weight(idx, value)}>
                  <Checkbox color={color(idx, value)} checked= { stateStore.visibleMapLayers[value] } onClick={(e) => layerSet(e, value, countyLayers)}> 
                    {value}
                  </Checkbox>
                </div>
              </li>
            ))}
            </div>
          <div className="label">
            <span>FACILITIES</span>
        </div>
        <div className="facChecks">
          {facilityLayers.map((value, idx) => (
              <li key={idx}>
              <div className="label" style={weight(idx, value)}>
                <Checkbox color={color(idx, value)} checked= { stateStore.visibleMapLayers[value] } onClick={(e) => layerSet(e, value, facilityLayers)}> 
                  {value}
                </Checkbox>
              </div>
            </li>
          ))}
          </div>
          <div style={{textAlign:"center", width: "80%", paddingBottom: "10px"}}>
            <Button className="label" onClick={() => {stateStore.currentModal='MapLegend'}}>
            Map legend
            </Button>
          </div>
        </ul>

      </li>
    )
  })

  return (
    <ul>
      <LayerSwitches></LayerSwitches>
    </ul>
  )
})

export { MapLayerSelector } 