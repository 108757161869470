import { computed, observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import jwtDecode from 'jwt-decode'
import { COVEX_AUTH_URL } from '../constants'
import { firebase, registerUser } from '../components/firebase'

const postHeaders = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
}

const authorizedRoles = [
  'admin',
  //'none',
  //'public',
  'contributor',
  'hospital_administrator',
  'outreach_agent'
]

const incorrectCredentialsMessage =
  'Incorrect username or password. Please try again.'
const incorrectCredentialsMessageBadRequest =
  'Incorrect username or password. Please try again. If you have signed up but not clicked the confirmation link, do so now.'

const loginErrorToCopy = {
  Unauthorized: incorrectCredentialsMessage,
  'Bad Request': incorrectCredentialsMessageBadRequest,
}

class AuthStore {
  @observable inProgress = false
  @observable error = undefined
  @persist @observable token = undefined
  @persist @observable userId = undefined
  @persist @observable email = undefined

  @computed get errorMsg() {
    if (this.error in loginErrorToCopy) {
      return loginErrorToCopy[this.error]
    }

    if (this.error) return this.error

    if (this.isExpired) {
      return 'Your login session has expired. Please sign in again'
    }

    return null
  }

  @computed get tokenProps() {
    if (this.token === null || this.token === undefined) {
      return null
    }

    const [preamble, tokenStr] = this.token.split('.')
    const tokenProps = JSON.parse(window.atob(tokenStr))

    return tokenProps
 }
   @computed get profileClaim() {
    if (this.tokenProps === null) {
      return null
    }

    return this.tokenProps['https://covid.helena.org']
  }

   @computed get firstName() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.first_name
  }

   @computed get lastName() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.last_name
  }

   @computed get explanation() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.explanation
  }

   @computed get organization() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.organization
  }

  @computed get organizationRole() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.organization_role
  }
 @computed get externalProfile() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.external_profile
  }

 
  @computed get isExpired() {
    if (this.tokenProps === null) {
      return false
    }

    const tokenExpiresAt = this.tokenProps.exp * 1000
    const now = new Date().getTime()

    return now > tokenExpiresAt
  }

  @computed get isAuthenticated() {
    return this.token !== undefined && this.token !== null && !this.isExpired
  }

  @computed get role() {
    if (this.tokenProps === null) {
      return null
    }

    const loginInfo = this.tokenProps['https://hasura.io/jwt/claims']
    let role = undefined
    try {
      role = loginInfo['x-hasura-default-role']
    } catch (error) {
      role = undefined
    }

    return role
  }

  @computed get isAuthorized() {
    return authorizedRoles.includes(this.role)
  }

  redirectToLogin = () => this.email && !this.isAuthenticated

  @action login = async ({ email, password }) => {
    this.inProgress = true
    this.error = undefined
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      const token = await firebase.auth().currentUser.getIdToken()
      const isVerified = firebase.auth().currentUser.emailVerified
      const decoded_token = jwtDecode(token)
      const hasuraClaims = decoded_token['https://hasura.io/jwt/claims']
      if (hasuraClaims === undefined || !isVerified) {
        throw Error('Signup is in progress. Please wait until you recieve a confirmation email.')
      }
      this.token = token
      this.email = email
      this.userId = firebase.auth().currentUser.uid
      //const decoded_token = jwtDecode(token)
      //const hasuraClaims = decoded_token['https://hasura.io/jwt/claims']
      //this.userId = hasuraClaims['x-hasura-user-id']
    } catch (error) {
      this.error = error.message
    }
    this.inProgress = false
  }

  //@action signup = async ({ email, password }) => {
  @action signup = async (data) => {
    this.inProgress = true
    this.error = undefined
    try {
      //await firebase.auth().createUserWithEmailAndPassword(email, password)
      const signupResult = await registerUser(data)
      console.log(this.signupResult)
    } catch (error) {
      console.log(error.message)
      this.error = error.message
    }
    this.inProgress = false
  }

  @action logout = () => {
    this.email = undefined
    this.token = undefined
  }
}

export default AuthStore
