import gql from 'graphql-tag'

export const GET_COUNTY_GEO = gql`
  query getCountyGeo {
    county_geo {
      features
      type
    }
  }
`

export const GET_COUNTY_FIELDS = gql`
  query getCountyFields {
    county {
      state
      county
      county_id
      cases
      cases_pct
      predicted_cases
      predicted_cases_pct
      deaths
      deaths_pct
      #predicted_deaths
      predicted_total_deaths
      predicted_total_deaths_pct
      #predicted_icu
      #predicted_vents
      population_pct
      #poverty
      poverty_pct
      hcc_score
      #social_distance_score1
      #hcc_score_pct
      social_distance_score2
      social_distance_score2_pct
      cases_delta_pct
      deaths_delta_pct
    }
  }
`

export const GET_HOSPITAL = gql`
  query getHospitalsMap {
    hospital {
      beds
      county_id
      hospital_name
      lat
      long
      phone
      ppe_requests
      risk_score
      risk_score_pct
      social_distance_score
      staff_requests
      type
      website
      beds_pct

    }
  }
`

export const REQUESTS_AUTH = gql`
  query requestsAuth($limit: Int!) {
    request(
      order_by: { timestamp: desc }
      limit: $limit
    ) {
      priority_score
      timestamp
      item
      county {
        county
      }
      county_id
      predicted
      hospital_id
      hospital_name
      cases
      capacity
    }
  }
`
export const REQUESTS = gql`
  query requests($limit: Int!, $auth: Boolean!) {
    request(
      order_by: { timestamp: desc }
      limit: $limit
    ) {
      priority_score
      timestamp
      item
      county {
        county
      }
      county_id
      predicted
      hospital @include(if: $auth) {
        hospital_name
      }
    }
  }
`