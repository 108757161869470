import React, { useState } from 'react'
import { Select, Icon, Tag, AutoComplete, message } from 'antd';
import { observer } from 'mobx-react'
import '../../styles/custom/search.css'
import { GET_COUNTY, GET_ITEMS, GET_HOSPITAL, GET_STATE ,
    GET_COUNTY_SEARCH, GET_HOSPITAL_SEARCH, GET_ITEM_SEARCH, 
    GET_STATE_SEARCH, GET_CITY_SEARCH, SEARCH_REQUESTS_COUNT
        } from '../../queries/RequestList.gql.js'
import { useQuery, ApolloConsumer } from '@apollo/react-hooks'
import { Spin } from 'antd'
import { stateStore, authStore } from '../../store'
import { toJS } from 'mobx'
import { SendResponseEmailModal } from '../Modals';


function filterCategory(value, string) {
    return value.filter((obj)=>{
        return obj.label == string
    })
}



const GlobalSearchBar = observer(() => {


    const [first, setFirst] = useState({}) // contains first object selected by search bar. Need to do this because onDeselect returns {} for this object

    function handleSelect(e, value) {

        if (stateStore.selectedItemFilters.length == 0 && stateStore.selectedCounties.length == 0 
            && stateStore.selectedStates.length == 0 && stateStore.selectedCities.length == 0 && stateStore.selectedFacilities.length == 0) {
                setFirst(value)
        }
        if (value.label == 'item') {
            stateStore.selectedItemFilters.push(value.value)
        }
        if (value.label == 'county') {
            stateStore.selectedCounties.push(value.key)
        }
        if (value.label == 'city') {
            stateStore.selectedCities.push(value.key)
        }
        if (value.label == 'state') {
            stateStore.selectedStates.push(value.value)
        }
        if (value.label == 'hospital') {
            stateStore.selectedFacilities.push(value.value)
        }
        message.loading({ content: 'Loading Requests', key:'1'});

    }
    function handleDeselect(e, value) {

    
        let obj = value
    
        // API returns an empty object when the first item is deselected
        if (Object.keys(obj).length == 0) {
            obj = first
            // 'DESELECTING FIRST'
        }
    
        if (obj.label == 'item') {
            stateStore.selectedItemFilters = toJS(stateStore.selectedItemFilters).filter((elt) => elt != obj.value)
        }
        if (obj.label == 'county') {
            stateStore.selectedCounties = toJS(stateStore.selectedCounties).filter((elt) => elt != obj.key)
        }
        if (obj.label == 'city') { // use city ids
            stateStore.selectedCities = toJS(stateStore.selectedCities).filter((elt) => elt != obj.key)
        }
        if (obj.label == 'state') {
            stateStore.selectedStates = toJS(stateStore.selectedStates).filter((elt) => elt != obj.value)
        }
        if (obj.label == 'hospital') {
            stateStore.selectedFacilities = toJS(stateStore.selectedFacilities).filter((elt) => elt != obj.value)
        }
    
    }

    function handleChange(e, value) {


    
    }
   

    function tagRender(props) {
        const { label, value } = props;
      
            return (
            <Tag color={value} style={{ marginRight: 3 }}>
                {label}
            </Tag>
            );
        }


    if (stateStore.updatedRequests) {
        const requestCount = stateStore.totalRequestsShown
        const requestCountRounded =
        requestCount < 1000
            ?  `Loaded ${requestCount}  matching requests`
            : `Loaded  ${Math.round(requestCount / 1000)}k matching requests`
        
        message.success({ content: requestCountRounded, key:'1', duration: 1});
        stateStore.updatedRequests = false
    }

    const [searchString, setSearchString] = React.useState()
    const hospitalResponse = useQuery(GET_HOSPITAL_SEARCH, {
        variables: { search_string: '% ' + searchString + '%', search_string2: searchString + '%', limit: 10}
    })
    const itemResponse = useQuery(GET_ITEM_SEARCH, {
        variables: { search_string: '% ' + searchString + '%', search_string2: searchString + '%', limit: 10}
    })
    const countyResponse = useQuery(GET_COUNTY_SEARCH, {
        variables: { search_string: '% ' + searchString + '%', search_string2: searchString + '%', limit: 10}
    })
    const stateResponse = useQuery(GET_STATE_SEARCH, {
        variables: { search_string: '% ' + searchString + '%', search_string2: searchString + '%', limit: 10}
    })
    const cityResponse = useQuery(GET_CITY_SEARCH, {
        variables: { search_string: '% ' + searchString + '%', search_string2: searchString + '%', limit: 10}
    })

    const loading =
        hospitalResponse.loading ||
        countyResponse.loading ||
        itemResponse.loading ||
        stateResponse.loading ||
        cityResponse.loading
    const error =
        hospitalResponse.error ||
        countyResponse.error ||
        itemResponse.error ||
        stateResponse.error ||
        cityResponse.error


        let hospitalData = hospitalResponse.data?.hospital
        let stateData = stateResponse.data?.state
        let countyData = countyResponse.data?.county
        let itemData = itemResponse.data?.item
        let cityData = cityResponse.data?.city

          //Remove duplicate keys
        if (hospitalData) {
            hospitalData = Object.values(hospitalData.reduce( (accum, val) => { accum[val.hospital_id] = val; return accum}, {}))
        }

        if (stateData) {
            stateData = Object.values(stateData.reduce( (accum, val) => { accum[val.state] = val; return accum}, {}))
        }

        if (countyData) {
            countyData = Object.values(countyData.reduce( (accum, val) => { accum[val.county_id] = val; return accum}, {}))
        }

        if (cityData) {
            cityData = Object.values(cityData.reduce( (accum, val) => { accum[val.city_id] = val; return accum}, {}))
        }

        if (itemData) {
            itemData = Object.values(itemData.reduce( (accum, val) => { accum[val.item_id] = val; return accum}, {}))
        }

    if (stateData) {
        console.log(stateData.length)
    } 

    function onSearch(value)  {
        if ( value.length >= 2) {
            setSearchString(value)
            
        } else {
            setSearchString(';alkfj;alkjsdf') //hack to make no responses show
            hospitalData = []
            stateData = []
            countyData = []
            cityData = []
            itemData = []
        }

    }

  

    
        return(
            <>
            {console.log(
                'returning'
            )}
            <div className="search">
            <Select
                mode="multiple"
                style={{maxWidth: '60%' }}
                placeholder= { authStore.isAuthorized ?  
                    (<div style={{textAlign: 'center', width: '100%'}}>Search Requests</div>) 
                    : (<div style={{textAlign: 'center', width: '100%'}}>Search Requests</div>)
                 }
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                onSearch={onSearch}
                onChange={handleChange}
                suffixIcon={<Icon type="search"/>}
                optionLabelProp="option_label"
                showArrow={false}
                allowClear={false}
                labelInValue={true}
                defaultValue={[]}
            >
                <Select.OptGroup label="Items">
                    {itemData?.map(item => {return(
                        <Select.Option key={item.item_id} value={item.item_name}  label={"item"} option_label={item.item_name} title={item.item}>
                        <div>
                            {/* <Avatar style={{ background: "#10899e" }}>{item[0]}</Avatar>{" "} */}
                            {item.item_name}
                        </div>
                        </Select.Option>
                    )})}
                </Select.OptGroup>
                { authStore.isAuthorized ? (
                <Select.OptGroup label="Hospital">
                    {hospitalData?.map(hospital => (
                        <Select.Option key={hospital.hospital_id} value={hospital.hospital_name} label={"hospital"} option_label={hospital.hospital_name}>
                        <div>
                            {/* <Avatar style={{ background: "#10899e" }}>{item[0]}</Avatar>{" "} */}
                            {hospital.hospital_name}
                        </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
                    ) : (<></>)}

                <Select.OptGroup label="City">
                    {cityData?.map(city => (
                        <Select.Option key={city.city_id} value={city.city_name} label={"city"} option_label={city.city_name}>
                        <div>
                            {/* <Avatar style={{ background: "#10899e" }}>{item[0]}</Avatar>{" "} */}
                            {city.city_name}
                        </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
                <Select.OptGroup label="County">
                    {countyData?.map(county => (

                        <Select.Option key={county.county_id} value={county.county_name} label={"county"}>
                        <div>
                            {/* <Avatar style={{ background: "#10899e" }}>{item[0]}</Avatar>{" "} */}
                            {county.county_name}
                        </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
                <Select.OptGroup label="State">
                    {stateData?.map(state=> (
                        <Select.Option key={state.state} value={state.state} label={"state"} option_label={state.state}>
                        <div>
                            {/* <Avatar style={{ background: "#10899e" }}>{item[0]}</Avatar>{" "} */}
                            {state.state}
                        </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            </Select>
            
            </div>
            </>
            )
})
export { GlobalSearchBar }